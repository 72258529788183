import React from "react";
import { calculatePercentage } from "../../utils/utils";
import { StyledProperties } from "../common/change-themes/styles-component";
import BedroomIcon from "../../images/goal-insight/bedroom.svg";
import BathroomIcon from "../../images/goal-insight/bathroom.svg";
import GarageIcon from "../../images/goal-insight/house-garage.svg";

const OwnedProperty = ({ ownedProperties }) => {
  return (
    <div className="mb-2 space-y-5">
      <span className="text-2xl font-[700] leading-5">Owned Properties</span>
      {ownedProperties.map((property) => {
        return (
          <div className="h-full" key={property.id}>
            <div className="flex justify-between w-full bg-black rounded h-10 py-2 px-4 text-sm mb-1">
              <span>{property.address}</span>
              <div className="flex w-1/5 h-5 gap-4 justify-end">
                <div className="flex space-x-1">
                  <img src={BedroomIcon} alt="" />
                  <span>{property.bedrooms}</span>
                </div>
                <div className="flex space-x-1">
                  <img src={BathroomIcon} alt="" />
                  <span>{property.bathrooms}</span>
                </div>
                <div className="flex space-x-1">
                  <img src={GarageIcon} alt="" />
                  <span>{property.carParks}</span>
                </div>
              </div>
            </div>
            <StyledProperties className="w-full p-4 rounded">
              <div className="flex gap-5 text-sm">
                <div className=" flex flex-col uppercase text-xs">
                  <span className="upercase ">Purchase Price</span>
                  <span className=" text-xl font-semibold">
                    {property.purchasePrice.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
                <div className=" flex flex-col uppercase text-xs">
                  <span className="upercase ">Estimated Value*</span>
                  <span className=" text-xl font-semibold">
                    {property.estimate.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
                <div className=" flex flex-col uppercase text-xs">
                  <span className="upercase ">Capital Growth</span>
                  <span className=" text-xl font-semibold">
                    {(
                      property.estimate.amount - property.purchasePrice.amount
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
              </div>
            </StyledProperties>
          </div>
        );
      })}
    </div>
  );
};

export default OwnedProperty;
