import {
  LOGIN_WITH_EMAIL_REQUEST,
  LOGIN_WITH_EMAIL_SUCCESS,
  LOGIN_WITH_EMAIL_FAILURE,
  LOGIN_WITH_PHONE_REQUEST,
  LOGIN_WITH_PHONE_SUCCESS,
  LOGIN_WITH_PHONE_FAILURE,
  REMOVE_TOKEN,
} from "../actions/authenticate-actions";

import { AnyAction } from "redux";

const initialState = {
  loginToken: "",
  loading: false,
  error: null,
  isSuccess: true,
};

const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case LOGIN_WITH_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: true,
      };
    case LOGIN_WITH_EMAIL_SUCCESS:
      return {
        ...state,
        loginToken: action?.loginToken,
        loading: false,
        error: null,
        isSuccess: true,
      };
    case LOGIN_WITH_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error,
        isSuccess: false,
      };
    case LOGIN_WITH_PHONE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: true,
      };
    case LOGIN_WITH_PHONE_SUCCESS:
      return {
        ...state,
        user: action?.user,
        loading: false,
        error: null,
        isSuccess: true,
      };
    case LOGIN_WITH_PHONE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error,
        isSuccess: false,
      };
    case REMOVE_TOKEN:
      return {
        ...state,
        loginToken: "",
      };
    default:
      return state;
  }
};

export default authReducer;
