import {
  Configuration,
  ConfigurationParameters,
  Middleware,
  UserApi,
  BrandOfficeApi,
} from "@dineiro/dineiro-sdk-mobile";
import { dineiroApiUrl, environment, isDeveloper } from "../environment";

export const logMiddleware: Middleware = {
  pre: (context) => {
    const accessToken =
      environment === "dev" || environment === "staging"
        ? (context.init.headers as { [key: string]: string })[
            "Authorization"
          ]?.replace("Bearer ", "")
        : "*****";
    if (isDeveloper) {
      console.log(
        `Processing the request to ${context.url} using access token ${accessToken}`
      );
    }

    return Promise.resolve();
  },
  post: (context) => {
    if (isDeveloper) {
      console.log(
        `Processed the request to ${context.url}. Status ${context.response.status}`
      );
    }
    return Promise.resolve();
  },
};

export const getUserApi = (token: string) => {
  const configParameters: ConfigurationParameters = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    basePath: dineiroApiUrl,
    middleware: [logMiddleware],
  };
  const config = new Configuration(configParameters);
  const usersApi = new UserApi(config);
  return usersApi;
};

// export const getUserAccountsApi = (token: string) => {
//   const configParameters: ConfigurationParameters = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     basePath: dineiroApiUrl,
//     middleware: [logMiddleware],
//   };

//   const config = new Configuration(configParameters);
//   const userAccountsApi = new AccountsApi(config);
//   return userAccountsApi;
// };

export const getBrandOfficesApi = (token: string) => {
  const configParameters: ConfigurationParameters = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    basePath: dineiroApiUrl,
    middleware: [logMiddleware],
  };

  const config = new Configuration(configParameters);
  const brandOfficesApi = new BrandOfficeApi(config);
  return brandOfficesApi;
};

// export const getPropertiesApi = (token: string) => {
//   const configParameters: ConfigurationParameters = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     basePath: dineiroApiUrl,
//     middleware: [logMiddleware],
//   };

//   const config = new Configuration(configParameters);
//   const propertiesApi = new PropertiesApi(config);
//   return propertiesApi;
// };

// export const getVehiclesApi = (token: string) => {
//   const configParameters: ConfigurationParameters = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     basePath: dineiroApiUrl,
//     middleware: [logMiddleware],
//   };

//   const config = new Configuration(configParameters);
//   const vehiclesApi = new VehiclesApi(config);
//   return vehiclesApi;
// };

// export const getHoldingsApi = (token: string) => {
//   const configParameters: ConfigurationParameters = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     basePath: dineiroApiUrl,
//     middleware: [logMiddleware],
//   };

//   const config = new Configuration(configParameters);
//   const holdingsApi = new HoldingsApi(config);
//   return holdingsApi;
// };
