import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { PAGE_URL } from "./constants/constants";
import Login from "./pages/authenticate/Login";
import Layout from "./pages/Layout";
import Home from "./pages/admin-home/Home";
import Verify from "./pages/authenticate/Verify";
import AdminContent from "./pages/admin-content/AdminContent";
import CustomerPage from "./pages/customer/CustomerPage";
import ResetPassword from "./pages/authenticate/ResetPassword";
import AdminMessage from "./pages/admin-message/AdminMessage";
import IndexComponent from "./pages/IndexComponent";
import LoginWithPhoneNumber from "./pages/authenticate/LoginWithPhoneNumber";
import RoleGuard from "./guard/AuthGuard";
import HomePage from "./pages/HomePage";
import RefreshToken from "./guard/RefreshToken";
import DocumentInApp from "./pages/customer/DocumentInApp";

const router = createBrowserRouter([
  {
    path: PAGE_URL.LOGIN,
    element: <Login />,
  },

  {
    path: PAGE_URL.LOGIN_WITH_PHONE_NUMBER,
    element: <LoginWithPhoneNumber />,
  },

  {
    path: PAGE_URL.VERIFY,
    element: <Verify />,
  },
  {
    path: PAGE_URL.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: "/",
    element: <IndexComponent></IndexComponent>,
  },
  {
    path: PAGE_URL.CUSTOMERS_IN_APP,
    element: (
      <RefreshToken>
        <DocumentInApp />
      </RefreshToken>
    ),
  },
  {
    path: PAGE_URL.HOME,
    element: (
      <RefreshToken>
        <Layout />
      </RefreshToken>
    ),
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: PAGE_URL.CUSTOMERS,
        element: (
          <RoleGuard
            accessibleRoles={["broker", "office-manager", "brand-manager"]}
          >
            <Home />
          </RoleGuard>
        ),
      },
      {
        path: PAGE_URL.ADMIN_CONTENT,
        element: (
          <RoleGuard
            accessibleRoles={[
              "marketer",
              "broker",
              "office-manager",

              "brand-manager",
            ]}
          >
            <AdminContent />
          </RoleGuard>
        ),
      },
      {
        path: PAGE_URL.ADMIN_MESSAGE,
        element: (
          <RoleGuard
            accessibleRoles={["broker", "office-manager", "brand-manager"]}
          >
            <AdminMessage />
          </RoleGuard>
        ),
      },
      {
        path: PAGE_URL.CUSTOMER_ASSETS,
        element: (
          <RoleGuard
            accessibleRoles={[
              "broker",
              "office-manager",
              "customer",
              "brand-manager",
            ]}
          >
            <CustomerPage />
          </RoleGuard>
        ),
      },
    ],
  },
]);
export default router;
