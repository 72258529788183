import { Action } from "redux";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export interface CreateUserAction extends Action {
  token: string;
  id: string;
  brandOfficeCreateCustomer: {
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    postcode: string;
    state: string;
    refererSource: string;
    subReferer: string;
    phoneNumber: string;
    brokerId?: string;
  };
}

export const createUserAction = ({
  token,
  id,
  brandOfficeCreateCustomer: {
    firstName,
    lastName,
    email,
    address,
    postcode,
    state,
    refererSource,
    subReferer,
    phoneNumber,
  },
}: CreateUserAction): CreateUserAction => {
  return {
    type: CREATE_USER,
    token,
    id,
    brandOfficeCreateCustomer: {
      firstName,
      lastName,
      email,
      address,
      postcode,
      state,
      refererSource,
      subReferer,
      phoneNumber,
    },
  };
};

export interface CreateUserActionSuccess extends Action {}
export const createUserActionSuccess = (): CreateUserActionSuccess => {
  return {
    type: CREATE_USER_SUCCESS,
  };
};

export interface CreateUserActionError extends Action {}
export const createUserActionError = (): CreateUserActionError => {
  return {
    type: CREATE_USER_ERROR,
  };
};
