import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import authReducer from "./reducer-authentication";
import userReducer from "./reducer-user";
import ErrorReducer from "./reducer-error";

const rootReducer = combineReducers({
  routing: routerReducer,
  auth: authReducer,
  errorReducer: ErrorReducer,
  user: userReducer,
});

export default rootReducer;
