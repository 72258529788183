import styled from "styled-components";
import "../../../styles.scss";

interface StyledComponentProps {
  topLeftColor?: string;
  bottomRightColor?: string;
  buttonColor?: string;
  logoUrl?: string;
}

const getStoredProps = (): StyledComponentProps => {
  const storedProps = localStorage.getItem("styledComponentProps");
  if (storedProps) {
    return JSON.parse(storedProps);
  }
  return {};
};

const setStoredProps = (props: StyledComponentProps) => {
  localStorage.setItem("styledComponentProps", JSON.stringify(props));
};

const StyledComponent = styled.div<StyledComponentProps>`
  background: ${(props) =>
    props.topLeftColor && props.bottomRightColor
      ? `linear-gradient(to bottom right, ${props.topLeftColor}, ${props.bottomRightColor})`
      : getStoredProps().topLeftColor && getStoredProps().bottomRightColor
      ? `linear-gradient(to bottom right, ${getStoredProps().topLeftColor}, ${
          getStoredProps().bottomRightColor
        })`
      : "var(--gradient-blue)"};
  color: white;
  font-family: SofiaProLight, sans-serif;
  overflow: auto;
  input::placeholder {
    color: white;
  }
`;

const StyledButton = styled.button<StyledComponentProps>`
  background: ${(props) =>
    props.buttonColor ||
    getStoredProps().buttonColor ||
    "rgba(120, 190, 32, 1)"};
  color: white;
  font-family: SofiaProLight, sans-serif;
`;

const StyledSecondButton = styled.button<StyledComponentProps>`
  background: rgba(0, 0, 0, 0.25);
  font-family: SofiaProLight, sans-serif;
`;

const StyledCheckBox = styled.input<StyledComponentProps>`
  color: ${(props) =>
    props.buttonColor ||
    getStoredProps().buttonColor ||
    "rgba(120, 190, 32, 1)"};
  font-family: SofiaProLight, sans-serif;
`;

const StyledTabBar = styled.div<StyledComponentProps>`
  position: relative;
  color: white;
  font-family: SofiaProLight, sans-serif;
  background: rgba(255, 255, 255, 0.15);
`;

const StyledProperties = styled.div<StyledComponentProps>`
  color: white;
  font-family: SofiaProLight, sans-serif;
  background: rgba(255, 255, 255, 0.15);
`;

const StyledSecondaryProperties = styled.div<StyledComponentProps>`
  background: rgba(255, 255, 255, 0.3);
  color: white;
  font-family: SofiaProLight, sans-serif;
`;

const StyledFolders = styled.button<StyledComponentProps>`
  background: rgba(255, 255, 255, 0.15);
  color: white;
  font-family: SofiaProLight, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  height: 100px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  img {
    width: 32px;
    height: 32px;
    margin-bottom: 2px;
  }
`;

const StyledTableHeader = styled.tr<StyledComponentProps>`
  background: rgba(255, 255, 255, 0.15);
  color: white;
  font-family: SofiaProLight, sans-serif;
`;

const StyledModal = styled.div<StyledComponentProps>`
  font-family: SofiaProLight, sans-serif;
`;

export {
  StyledComponent,
  StyledButton,
  StyledTabBar,
  StyledProperties,
  StyledSecondaryProperties,
  StyledFolders,
  StyledTableHeader,
  StyledCheckBox,
  StyledModal,
  StyledSecondButton,
  getStoredProps,
  setStoredProps,
};
