import exp from "constants";

export function getObjectToQueryParams(options) {
  return options
    ? Object.keys(options)
        .filter((o) => options[o] !== undefined && options[o] !== null)
        .map((o) => `${o}=${options[o]}`)
        .join("&")
    : "";
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function generateRandomCode() {
  let code = "";
  for (let i = 0; i < 8; i++) {
    const randomDigit = Math.floor(Math.random() * 10);
    code += randomDigit;
  }
  return code;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function getUniqueStartCharacters(users) {
  const initials = users
    .reduce((initials, user) => {
      const firstLetter = user.firstName.charAt(0).toUpperCase();
      if (!initials.includes(firstLetter)) {
        return [...initials, firstLetter];
      }
      return initials;
    }, [])
    .sort();

  return initials;
}

export function sortFullNames(data, isAscending = true) {
  const sorted = [...data].sort((a, b) => {
    if (isAscending) {
      return a.firstName.localeCompare(b.firstName);
    } else {
      return b.firstName.localeCompare(a.firstName);
    }
  });
  return sorted;
}

export function isExpired(createdAt) {
  const twentyFourHoursAgo = new Date();
  twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

  const createdAtDate = new Date(createdAt);

  return createdAtDate < twentyFourHoursAgo;
}

export function calculatePercentage(value1, value2, decimalPlaces) {
  const percentage = (value1 / value2) * 100;
  const roundedPercentage = percentage.toFixed(decimalPlaces);
  const roundedPercentageInt = parseInt(roundedPercentage, 10);
  const result =
    roundedPercentageInt === parseFloat(roundedPercentage)
      ? roundedPercentageInt
      : roundedPercentage;

  return `${result}%`;
}

export function isFileInFolder(folderName, filePath) {
  const regex = new RegExp(`^${folderName}`);
  if (regex.test(filePath)) {
    return true;
  } else {
    return false;
  }
}

export function formatDate(date: Date): string {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

export function handleNavigate(tab: string): string {
  const currentUrl = window.location.pathname;
  const hasIdParam = currentUrl.includes("/");

  let cleanUrl;

  if (hasIdParam) {
    const idParamIndex = currentUrl.lastIndexOf("/");
    cleanUrl = currentUrl.substring(0, idParamIndex);
  } else {
    cleanUrl = currentUrl;
  }

  return `${cleanUrl}/${tab}`;
}

export function getLocalStorageElement(key: string): string {
  const storedToken = localStorage.getItem(key);
  if (storedToken) {
    return storedToken;
  }
  return "";
}

export function reformatPhoneNumber(phone) {
  if (phone.startsWith("+")) {
    return phone;
  } else {
    return "+61" + phone.substring(1);
  }
}
