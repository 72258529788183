import React, { useState, useEffect, useCallback, useRef } from "react";
import FolderIcon from "../../images/document/folder-icon.svg";
import UploadIcon from "../../images/document/upload-icon.svg";
import DeleteIcon from "../../images/document/delete-icon.svg";
import FileIcon from "../../images/document/file-icon.svg";
import OptionIcon from "../../images/document/option-icon.svg";
import DownloadIcon from "../../images/document/download-icon.svg";
import AddFolderIcon from "../../images/document/add-folder-icon.svg";
import SearchIcon from "../../images/document/search-icon.svg";
import {
  formatBytes,
  formatDate,
  generateRandomCode,
  getLocalStorageElement,
  isFileInFolder,
} from "../../utils/utils";
import { useForm } from "react-hook-form";
import { StyledFolders } from "../../components/common/change-themes/styles-component";
import Button from "../../components/common/button/Button";
import { getBrandOfficesApi } from "../../services/api-service";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Modal from "../../components/common/modal/Modal";
import useModal from "../../hooks/useModal";
import { BinaryFileDTO } from "@dineiro/dineiro-sdk-mobile";
import FolderFilesList from "../../components/document/FolderFilesList";

const DocumentTab: React.FC<{
  folders: BinaryFileDTO[];
  files: BinaryFileDTO[];
  refetch;
  shouldRefetch;
  setSearchValue;
  searchValue;
}> = ({
  folders,
  files,
  refetch,
  shouldRefetch,
  setSearchValue,
  searchValue,
}) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const { officeid, tab, id, customername } = useParams();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [readyToUpLoads, setReadyToUpLoads] = useState([]);
  const hiddenFileInput = useRef(null);
  const [fileStored, setFileStored] = useState([]);

  const { show: showCreateFolderModal, toggle: toggleCreateFolderModal } =
    useModal();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {}, [folders, uploadedFiles]);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const updatedFiles = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      updatedFiles.push(files[i]);
    }
    setUploadedFiles(updatedFiles);
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const files = event.dataTransfer.files;
    const updatedFiles = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      updatedFiles.push(files[i]);
    }

    setUploadedFiles(updatedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDeleteFile = (index, isUploadfile) => {
    if (isUploadfile) {
      const updatedFiles = [...uploadedFiles];
      updatedFiles.splice(index, 1);
      setUploadedFiles(updatedFiles);
    }
    const updatedFiles = [...fileStored];
    updatedFiles.splice(index, 1);
    setFileStored(updatedFiles);
  };

  const handleSelectFolder = async (index, event) => {
    const { value } = event.target;
    const updatedFiles = [...uploadedFiles];

    const path = `${value}/${updatedFiles[index].name}`;

    const blob = new Blob([updatedFiles[index]], {
      type: updatedFiles[index].type,
    });
    updatedFiles[index] = {
      path: path,
      file: blob,
      isDirectory: false,
    };

    if (readyToUpLoads.length > 0) {
      if (readyToUpLoads[index]) {
        readyToUpLoads[index] = updatedFiles[index];
      } else setReadyToUpLoads([...readyToUpLoads, updatedFiles[index]]);
    } else setReadyToUpLoads(updatedFiles);
  };

  const onSubmit = (data) => {
    updateFilesInFolders();
  };

  const updateFilesInFolders = () => {
    let checkExist = 0;
    readyToUpLoads.map((file) => {
      const existingFiles = files.filter(
        (exist) => exist.fullName === file.path
      );
      if (existingFiles.length > 0) checkExist = checkExist + 1;
    });
    if (checkExist > 0)
      return toast.error(
        "An existing file is found in the same folder. Please delete the old file before uploading."
      );
    else {
      const userReportApi = getBrandOfficesApi(storedIdToken);
      readyToUpLoads.map((blob) => {
        toast.promise(
          userReportApi
            .brandOfficeCustomersFilesPost({
              id: parseInt(officeid),
              userId: id,
              file: blob.file,
              path: blob.path,
              isDirectory: false,
            })
            .then((respone) => {
              refetch(!shouldRefetch);
              setReadyToUpLoads([]);
              setUploadedFiles([]);
            }),
          {
            loading: "Saving...",
            success: <b>Upload files successful.</b>,
            error: <b>Failed to upload files.</b>,
          }
        );
      });
    }
  };

  const checkNullFilesInFolders = () => {
    if (files.length > 0) return false;
    return true;
  };

  const handleAddNewClick = () => {
    toggleCreateFolderModal();
  };

  const handleCreateFolder = (data) => {
    if (data.folderName !== "") {
      const userReportApi = getBrandOfficesApi(storedIdToken);
      toast.promise(
        userReportApi
          .brandOfficeCustomersFilesPost({
            id: parseInt(officeid),
            userId: id,
            path: data.folderName,
            isDirectory: true,
          })
          .then((respone) => {
            refetch(!shouldRefetch);
            reset();
            toggleCreateFolderModal();
          }),
        {
          loading: "Saving...",
          success: <b>Create folder successful.</b>,
          error: <b>Failed to create folder.</b>,
        }
      );
    }
  };

  const onDeleteFileUploaded = (fileId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersFilesDeleteDelete({
          id: parseInt(officeid),
          userId: id,
          fileId: fileId,
        })
        .then((respone) => {
          refetch(!shouldRefetch);
        }),
      {
        loading: "Saving...",
        success: <b>Delete file successful.</b>,
        error: <b>Failed to delete file.</b>,
      }
    );
  };

  const handleDownload = (downloadLink, fileName) => {
    const link = document.createElement("a");
    link.download = fileName;

    link.href = downloadLink;

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  const onDownloadFile = (fileId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersFilesDetailsGet({
          id: parseInt(officeid),
          userId: id,
          fileId: fileId,
        })
        .then((respone) => {
          handleDownload(respone.data.downloadLink, respone.data.name);
          refetch(!shouldRefetch);
        }),
      {
        loading: "Downloading...",
        success: <b>File downloaded successfully.</b>,
        error: <b>Failed to download file.</b>,
      }
    );
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setSearchValue(event.target.value);
    }
  };

  const handleSearchChange = (event) => {
    if (event.target.value === "") {
      setSearchValue(event.target.value);
    }
  };

  return (
    <div className="my-10 w-[100%]">
      <div className="flex justify-center text-sm font-semibold mb-5">
        <h3 className="text-3xl header-h3 font-bold">Documents</h3>
      </div>
      <div className="grid grid-cols-7 gap-2">
        <div className="col-start-2 col-span-2">
          <div className="min-w-[200px] max-w-lg flex justify-center relative mb-3">
            <input
              type="text"
              placeholder="Search File"
              className="bg-black/20 w-full h-11 pl-10 pr-3 rounded-lg text-sm border-none focus:ring-white"
              onChange={(e) => handleSearchChange(e)}
              onKeyDown={(e) => handleSearch(e)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400 ">
              <img src={SearchIcon} alt="" />
            </div>
          </div>

          {folders.length > 0 ? (
            <div className="grid grid-cols-3 gap-4 py-3 min-w-[200px] max-w-[100%]">
              {folders.map((folder) => (
                <StyledFolders key={folder.id}>
                  <img src={FolderIcon} alt="" />
                  <span>{folder.name}</span>
                </StyledFolders>
              ))}
              <StyledFolders onClick={handleAddNewClick}>
                <img src={AddFolderIcon} alt="" />
                <span>Add New</span>
              </StyledFolders>
            </div>
          ) : searchValue !== "" ? null : (
            <div className="grid grid-cols-3 gap-4 py-3 min-w-[200px] max-w-[100%]">
              <StyledFolders onClick={handleAddNewClick}>
                <img src={AddFolderIcon} alt="" />
                <span>Add New</span>
              </StyledFolders>
            </div>
          )}
        </div>
        <div className="col-span-3 text-sm ml-7">
          <FolderFilesList
            folders={folders}
            files={files}
            searchValue={searchValue}
            onDownloadFile={onDownloadFile}
            onDeleteFileUploaded={onDeleteFileUploaded}
          ></FolderFilesList>

          <div className="mb-5 space-y-5">
            {folders.length > 0 && !checkNullFilesInFolders() ? (
              <span className="text-lg font-semibold">Upload Files</span>
            ) : null}
            <div className="w-4/5">
              <p>
                Upload your supporting docs into the folders provided. All data
                is stored in an encrypted fashion, to ensure your privacy and
                security.
              </p>
              <p>
                These documents are only stored temporarily and will
                automatically deleted from our system if left for more than 90
                days.
              </p>
            </div>
            {uploadedFiles.length > 0 && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <h5 className="header-h3 font-semibold">
                  Files to be uploaded
                </h5>
                <div className="my-4">
                  {uploadedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-2 dark:text-gray-400 border-y border-dashed"
                    >
                      <div className="flex flex-col py-2 w-[85%]">
                        <span className="text-base truncate">{file.name}</span>
                        <span className="truncate">
                          file size ({formatBytes(file.size)})
                        </span>
                      </div>
                      <div className="flex justify-end items-center py-2 gap-2">
                        <select
                          className="w-[200px] h-11 px-3 py-2 input_bg dark:text-white bg-black/20 pl-10 pr-3 rounded-lg text-sm border-none focus:ring-white"
                          id={`folder-${index}`}
                          name={`folder-${index}`}
                          value={file.folder}
                          onChange={(event) => handleSelectFolder(index, event)}
                          required
                        >
                          <option value="">Choose Folder</option>
                          {folders.map((folder) => (
                            <option key={folder.id} value={folder.name}>
                              {folder.name}
                            </option>
                          ))}
                        </select>
                        <img
                          className="cursor-pointer h-max py-2"
                          src={DeleteIcon}
                          onClick={() => handleDeleteFile(index, true)}
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <Button px="16px" borderRadius="6px" h="40px">
                  <span className="uppercase">Upload Files</span>
                </Button>
              </form>
            )}
          </div>

          <div
            className="flex items-start w-full"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-48 border-2 border-white/30 border-dashed rounded-lg cursor-pointer bg-transparent dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex items-center justify-center pt-5 pb-6">
                <img src={UploadIcon} alt="" />
                <p className="ml-4 text-2xl dark:text-gray-400">
                  <span>Drag & Drop a file to upload</span>
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                ref={hiddenFileInput}
                onClick={() => {
                  hiddenFileInput.current.value = null; // Hoặc đặt lại giá trị mặc định
                }}
                onChange={handleFileUpload}
                multiple
              />
            </label>
          </div>
        </div>
      </div>
      <Modal
        show={showCreateFolderModal}
        toggle={toggleCreateFolderModal}
        content={
          <div className=" rounded-xl bg-gray-200 px-2 py-4">
            <div className="flex flex-col items-center">
              <div className="w-full">
                <form
                  onSubmit={handleSubmit(handleCreateFolder)}
                  className="flex justify-center items-center gap-2"
                >
                  <div className="w-2/3 mb-4">
                    <label className=" font-semibold" htmlFor="folderName">
                      New Folder:
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-md"
                      id="folderName"
                      placeholder="Folder Name"
                      required
                      {...register("folderName")}
                    />
                  </div>
                  <div className=" justify-center">
                    <Button
                      w="100px"
                      h="40px"
                      px="16px "
                      py="8px"
                      borderRadius="0.375rem"
                      fontSize="12px"
                      lineHeight="16px"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        }
      ></Modal>
    </div>
  );
};

export default DocumentTab;
