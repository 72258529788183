import React, { useState, useEffect, useLayoutEffect } from "react";
import { db, logout, auth } from "../components/common/firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  StyledComponent,
  getStoredProps,
} from "../components/common/change-themes/styles-component";
import ProfileIcon from "../images/home/profile-icon.svg";
import LogoDosh from "../images/logo/dosh-logo.svg";
import { Menu, Transition } from "@headlessui/react";
import { CUSTOMER_TABS, PAGE_URL } from "../constants/constants";
import Spinner from "../components/common/spinner/Spinner";
import useUserInfoTK from "../hooks/useUserInfoTK";
import useBrandInfo from "../hooks/useBrandInfo";
import Modal from "../components/common/modal/Modal";
import Button from "../components/common/button/Button";
import { getLocalStorageElement } from "../utils/utils";

const HomePage = (props) => {
  const storedProps = getStoredProps();
  const [spinner, setSpinner] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const param = useParams();
  const [user, loading, error] = useAuthState(auth);
  const { role, brandOfficeId, userName, userId } =
    useUserInfoTK(storedIdToken);
  let officeId = parseInt(param.officeid);
  const { brandInfo, officeName, id } = useBrandInfo({
    brandOfficeId: param.officeid,
  });
  const [accessible, setAccessible] = useState(true);
  const [checkingAccess, setCheckingAccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (storedIdToken === "") {
      return navigate(
        PAGE_URL.LOGIN.replace(":brand", param.brand)
          .replace(":brandid", param.brandid)
          .replace(":office", param.office)
          .replace(":officeid", param.officeid)
      );
    }
  }, [storedIdToken, brandInfo]);

  useEffect(() => {
    if (storedIdToken && brandInfo !== undefined) {
      if (parseInt(param.brandid) !== brandInfo.id) {
        setAccessible(false);
      } else {
        if (officeId !== brandOfficeId && brandOfficeId !== 0) {
          setAccessible(false);
        } else setAccessible(true);
      }
    }

    if (accessible)
      if (storedIdToken && brandInfo) {
        if (role === "marketer") {
          navigate(
            `${PAGE_URL.HOME.replace(
              ":brand",
              brandInfo.name.toLowerCase().replace(/\s/g, "-")
            )
              .replace(":brandid", brandInfo.id.toString())
              .replace(":office", officeName.toLowerCase().replace(/\s/g, "-"))
              .replace(":officeid", id.toString())}/${PAGE_URL.ADMIN_CONTENT}`
          );
        } else if (role === "customer") {
          navigate(
            `${PAGE_URL.HOME.replace(
              ":brand",
              brandInfo.name.toLowerCase().replace(/\s/g, "-")
            )
              .replace(":brandid", brandInfo.id.toString())
              .replace(":office", officeName.toLowerCase().replace(/\s/g, "-"))
              .replace(
                ":officeid",
                id.toString()
              )}/${PAGE_URL.CUSTOMER_ASSETS.replace(":customername", userName)
              .replace(":id", userId)
              .replace(":tab", CUSTOMER_TABS.documents)}`
          );
        } else if (role !== "")
          navigate(
            `${PAGE_URL.HOME.replace(
              ":brand",
              brandInfo.name.toLowerCase().replace(/\s/g, "-")
            )
              .replace(":brandid", brandInfo.id.toString())
              .replace(":office", officeName.toLowerCase().replace(/\s/g, "-"))
              .replace(":officeid", id.toString())}/${PAGE_URL.CUSTOMERS}`
          );
      }
  }, [storedIdToken, brandInfo]);
  return <Outlet></Outlet>;
};

export default HomePage;
