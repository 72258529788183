import React, { useState, useEffect } from "react";
import AccountItem from "../../components/common/account/AccountItem";
import AccountItemWithType from "../../components/common/account/AccountItemWithType";
import { useParams } from "react-router-dom";
import { getBrandOfficesApi } from "../../services/api-service";
import toast from "react-hot-toast";
import {
  AccountDTO,
  CONTAINEREnum,
  OverriddenAccountTypeValue,
  PropertyDTO,
  VehicleDTO,
} from "@dineiro/dineiro-sdk-mobile";
import { getLocalStorageElement } from "../../utils/utils";
import { Holding } from "../../hooks/useHoldings";

const AssetsTab: React.FC<{
  properties: PropertyDTO[];
  banks: AccountDTO[];
  vehicles: VehicleDTO[];
  holdings: Holding[];
  supperAccounts: AccountDTO[];
  accounts: AccountDTO[];
  setChangeTypes;
  changeTypes;
}> = ({
  properties,
  banks,
  vehicles,
  holdings,
  supperAccounts,
  accounts,
  setChangeTypes,
  changeTypes,
}) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [total, setTotal] = useState(0);
  const [investments, setInvestments] = useState([]);
  const { officeid, tab, id, customername } = useParams();

  useEffect(() => {
    setInvestments(
      accounts.filter(
        (account) =>
          account.container === CONTAINEREnum.Investment &&
          !account.accountType.includes("SUPER_ANNUATION")
      )
    );
  }, [accounts]);

  useEffect(() => {
    const totalProperties = properties.reduce(
      (acc, obj) => acc + obj.estimate?.amount!,
      0
    );
    const totalBanks = banks.reduce(
      (acc, obj) => acc + obj.balance?.amount!,
      0
    );
    const totalVehicles = vehicles.reduce(
      (acc, obj) => acc + obj.estimationPrice?.amount!,
      0
    );
    const totalShares = holdings.reduce((acc, obj) => acc + obj.amount!, 0);
    const totalSupperAccounts = supperAccounts.reduce(
      (acc, obj) => acc + obj.balance?.amount!,
      0
    );

    const totalInvestments = investments.reduce(
      (acc, obj) => acc + obj.balance?.amount!,
      0
    );

    const totalAssets =
      totalProperties +
      totalBanks +
      totalVehicles +
      totalShares +
      totalSupperAccounts +
      totalInvestments;
    setTotal(totalAssets);
  }, [properties, banks, vehicles, supperAccounts, holdings, investments]);

  const onChangeTypes = (type, accountId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersAccountsAccountTypePatch({
          id: parseInt(officeid),
          userId: id,
          accountId: accountId,
          brandOfficeCustomersAccountsAccountTypePatchViewModel: {
            accountType: OverriddenAccountTypeValue[type],
          },
        })
        .then((respone) => {
          setChangeTypes(!changeTypes);
        }),
      {
        loading: "Saving...",
        success: <b>Change account type successful.</b>,
        error: <b>Failed to change type.</b>,
      }
    );
  };

  return (
    <>
      {total > 0 ? (
        <div className="my-10 p-2">
          <div className="flex justify-center text-sm font-semibold">
            <span>Change Acount Type</span>
          </div>
          <div className="p-2 text-[15px]">
            {properties && properties.length > 0 && (
              <ul className="mb-5 px-0">
                <div className="mb-2">
                  <span className="uppercase font-semibold">Property</span>
                </div>
                {properties.map((property) => {
                  return (
                    <AccountItem
                      key={property.id}
                      name={property.address}
                      amount={property.estimate.amount}
                    ></AccountItem>
                  );
                })}
              </ul>
            )}
            {vehicles && vehicles.length > 0 && (
              <ul className="mb-5 px-0">
                <div className="mb-2">
                  <span className=" uppercase font-semibold">Vehicle</span>
                </div>
                {vehicles.map((vehicle) => {
                  return (
                    <AccountItem
                      key={vehicle.id}
                      name={vehicle.description}
                      amount={vehicle.estimationPrice.amount}
                    ></AccountItem>
                  );
                })}
              </ul>
            )}
            {banks && banks.length > 0 && (
              <ul className="mb-5 px-0">
                <div className="mb-2">
                  <span className="uppercase font-semibold">Banking</span>
                </div>
                {banks.map((account) => {
                  return (
                    <AccountItem
                      key={account.id}
                      name={`${account.providerName}-${account.accountName} (
                      ${account.accountNumber.slice(-4)} 
                      )`}
                      amount={account.balance.amount}
                    ></AccountItem>
                  );
                })}
              </ul>
            )}

            {holdings && holdings.length > 0 && (
              <ul className="mb-5 px-0">
                <div className="mb-2">
                  <span className=" uppercase font-semibold">Holdings</span>
                </div>

                {holdings.map((holding) => {
                  return (
                    <AccountItemWithType
                      key={holding.id}
                      name={holding.title}
                      accountType={holding.accountType}
                      amount={holding.amount}
                      onChangeTypes={onChangeTypes}
                    ></AccountItemWithType>
                  );
                })}
              </ul>
            )}

            {investments && investments.length > 0 && (
              <ul className="mb-5 px-0">
                <div className="mb-2">
                  <span className=" uppercase font-semibold">Shares</span>
                </div>
                {investments.map((investment) => {
                  return (
                    <AccountItemWithType
                      key={investment.id}
                      name={`${investment.providerName}-${
                        investment.accountName
                      } (
                        ${investment.accountNumber.slice(-4)} 
                        )`}
                      accountType={investment.accountType}
                      container={investment.container}
                      amount={investment.balance.amount}
                      onChangeTypes={onChangeTypes}
                      accountId={investment.id}
                    ></AccountItemWithType>
                  );
                })}
              </ul>
            )}
            {supperAccounts && supperAccounts.length > 0 && (
              <ul className="mb-5 px-0">
                <div className="mb-2">
                  <span className=" uppercase font-semibold">Super</span>
                </div>

                {supperAccounts.map((supperAccount) => {
                  return (
                    <AccountItemWithType
                      key={supperAccount.id}
                      name={`${supperAccount.providerName}-${
                        supperAccount.accountName
                      } (
                        ${supperAccount.accountNumber.slice(-4)} 
                        )`}
                      accountType={supperAccount.accountType}
                      amount={supperAccount.balance.amount}
                      onChangeTypes={onChangeTypes}
                    ></AccountItemWithType>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="mt-5 p-2 text-[15px]">
            <div className="flex justify-between py-2 font-semibold border-t border-slate-400">
              <span>Total Assets</span>
              <span>
                {total.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center my-10 text-[15px]">
          This user does not provide any assets
        </div>
      )}
    </>
  );
};

export default AssetsTab;
