import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import rootSaga from "./saga/index";
import rootReducer from "./reducers/index";
import { createStore } from "redux";
import { applyMiddleware, compose } from "@reduxjs/toolkit";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof rootReducer>;
