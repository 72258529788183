import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/common/firebase/firebase";
import { jwtDecode } from "jwt-decode";
import { getLocalStorageElement } from "../utils/utils";
import { isDeveloper } from "../environment";

const useUserInfoTK = (token = "") => {
  const [userName, setName] = useState("");
  const [userId, setId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [role, setUserRole] = useState("");
  const [brandOfficeId, setBrandOfficeId] = useState(0);
  const storedIdToken = getLocalStorageElement("idToken");

  const UserInfoTKComponent = () => {
    useEffect(() => {
      if (token === "") {
        const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
        if (user && storedIdToken) {
          const fetchData = async () => {
            try {
              setName(user["name"]);
              setEmailAddress(user["email"]);
              setBrandOfficeId(user["brandOfficeId"]);
              setId(user["user_id"]);
              const role = user["roles"].find(
                (role) => role["brandOfficeId"] === user["brandOfficeId"]
              );

              setUserRole(role.role.name);
            } catch (err: any) {
              console.log(err);
            }
          };
          fetchData();
        } else {
          if (isDeveloper) console.log("No current user.");
        }
      } else {
        const user = jwtDecode(token);
        try {
          setName(user["name"]);
          setEmailAddress(user["email"]);
          setBrandOfficeId(user["brandOfficeId"]);
          setId(user["user_id"]);
          const role = user["roles"].find(
            (role) => role["brandOfficeId"] === user["brandOfficeId"]
          );

          setUserRole(role.role.name);
        } catch (err: any) {
          console.log(err);
        }
      }
    }, [storedIdToken, token]);

    return null;
  };
  UserInfoTKComponent();

  return {
    userName: userName,
    brandOfficeId: brandOfficeId,
    emailAddress: emailAddress,
    role: role,
    userId: userId,
  };
};

export default useUserInfoTK;
