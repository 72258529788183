import React, { useState } from "react";
import { formatBytes, formatDate, isFileInFolder } from "../../utils/utils";
import DeleteIcon from "../../images/document/delete-icon.svg";
import FileIcon from "../../images/document/file-icon.svg";
import OptionIcon from "../../images/document/option-icon.svg";
import DownloadIcon from "../../images/document/download-icon.svg";
import { BinaryFileDTO } from "@dineiro/dineiro-sdk-mobile";

const FolderFilesList: React.FC<{
  folders: BinaryFileDTO[];
  files: BinaryFileDTO[];
  onDownloadFile;
  onDeleteFileUploaded;
  searchValue;
}> = ({
  folders,
  files,
  searchValue,
  onDownloadFile,
  onDeleteFileUploaded,
}) => {
  const [isShow, setIsShow] = useState();

  const findFilesinFolder = (files, folderName) => {
    let listFiles = [];
    if (files.length > 0) {
      listFiles = files.filter((file) =>
        isFileInFolder(folderName, file.fullName)
      );
      return listFiles;
    }
  };

  const checkNullFilesInFolders = () => {
    if (files.length > 0) return false;
    return true;
  };

  const showOptions = (fileId) => {
    if (isShow === fileId) {
      setIsShow(null);
    } else setIsShow(fileId);
  };
  return (
    <div>
      {folders.length > 0 && !checkNullFilesInFolders() ? (
        <div className="mb-5">
          {folders.map((folder, index) => (
            <div key={index} className="mb-4 space-y-5">
              <span className=" text-lg font-semibold">{folder.name}</span>
              {files && files.length > 0 ? (
                <div>
                  {findFilesinFolder(files, folder.name).length > 0 ? (
                    findFilesinFolder(files, folder.name).map((file) => {
                      return (
                        <div
                          className="flex justify-between bg-black/20 w-full rounded-lg  text-sm mb-2"
                          key={file.id}
                        >
                          <div className="flex px-3 py-1 gap-2 w-[85%]">
                            <img className=" w-10" src={FileIcon} alt="" />
                            <div className="grid grid-rows-2 py-2 ">
                              <span className="text-base truncate ">
                                {file.name}
                              </span>
                              <span className="truncate">
                                file size ({formatBytes(file.size)}) - Uploaded
                                on {formatDate(file.createdAt)}
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-2 px-2">
                            {isShow === file.id ? (
                              <div className="flex gap-3 mr-2">
                                <button onClick={() => onDownloadFile(file.id)}>
                                  <img src={DownloadIcon} alt="" />
                                </button>

                                <button
                                  onClick={() => onDeleteFileUploaded(file.id)}
                                >
                                  <img src={DeleteIcon} alt="" />
                                </button>
                              </div>
                            ) : null}
                            <button
                              className="mr-2"
                              onClick={() => showOptions(file.id)}
                            >
                              <img src={OptionIcon} alt="" />
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No documents in this folder</div>
                  )}
                </div>
              ) : (
                <div>No documents in this folder</div>
              )}
            </div>
          ))}
        </div>
      ) : searchValue !== "" ? (
        <div className="mb-5">
          <div className="mb-4 space-y-5">
            <span className=" text-lg font-semibold">Files</span>
            {files && files.length > 0 ? (
              <div>
                {files.map((file) => {
                  return (
                    <div
                      className="flex justify-between bg-black/20 w-full rounded-lg  text-sm mb-2"
                      key={file.id}
                    >
                      <div className="flex px-2 py-1 gap-2">
                        <img className="w-1/4" src={FileIcon} alt="" />
                        <div className="grid grid-rows-2 py-2">
                          <span className="text-base truncate">
                            {file.name}
                          </span>
                          <span>
                            file size ({formatBytes(file.size)}) - Uploaded on{" "}
                            {formatDate(file.createdAt)}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-2 px-2">
                        {isShow === file.id ? (
                          <div className="flex gap-3 mr-2">
                            <button onClick={() => onDownloadFile(file.id)}>
                              <img src={DownloadIcon} alt="" />
                            </button>

                            <button
                              onClick={() => onDeleteFileUploaded(file.id)}
                            >
                              <img src={DeleteIcon} alt="" />
                            </button>
                          </div>
                        ) : null}
                        <button onClick={() => showOptions(file.id)}>
                          <img src={OptionIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>No documents in this folder</div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FolderFilesList;
