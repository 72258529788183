import { StyledButton } from "../change-themes/styles-component";

type ButtonProps = {
  children: React.ReactNode;
  w?: string;
  h?: string;
  borderRadius?: string;
  borderTopRightRadius?: string;
  borderBottomRightRadius?: string;
  px?: string;
  py?: string;
  bg?: string;
  fontSize?: string;
  lineHeight?: string;
  isDisabled?: boolean;
  onClick?: (e?) => void;
};

const Button = ({
  children,
  w,
  h,
  borderRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  px,
  py = "8px",
  bg,
  fontSize = "14px",
  lineHeight = "20px",
  isDisabled,
  onClick,
}: ButtonProps) => {
  const buttonStyle = {
    width: w,
    minHeight: h,
    borderRadius: borderRadius,
    borderTopRightRadius: borderTopRightRadius || borderRadius,
    borderBottomRightRadius: borderBottomRightRadius || borderRadius,
    paddingTop: py,
    paddingRight: px,
    paddingBottom: py,
    paddingLeft: px,
    background: bg,
    fontSize: fontSize,
    lineHeight: lineHeight,
    disable: isDisabled,
  };

  return (
    <StyledButton
      className="flex font-semibold gap-2 justify-center items-center uppercase"
      style={buttonStyle}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
