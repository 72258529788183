import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/common/firebase/firebase";
import { getBrandOfficesApi } from "../services/api-service";
import { OfficeArticleDTO } from "@dineiro/dineiro-sdk-mobile";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useBrandContents = ({ officeId }) => {
  const [contents, setContents] = useState<OfficeArticleDTO[]>([]);
  const [pendingContents, setPendingContents] = useState<OfficeArticleDTO[]>(
    []
  );
  const [shouldRefetch, refetch] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;

  const UseBrandContentsComponent = () => {
    useEffect(() => {
      if (user && storedIdToken) {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeArticlesGet({
                id: officeId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setContents(
                    response.data
                      .filter((officeArticles) => officeArticles.isApproved)
                      .sort((a, b) => b.id - a.id)
                  );
                  setPendingContents(
                    response.data
                      .filter((officeArticles) => !officeArticles.isApproved)
                      .sort((a, b) => b.id - a.id)
                  );
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, shouldRefetch]);

    return null;
  };

  UseBrandContentsComponent();

  return {
    contents: contents,
    pendingContents: pendingContents,
    refetch,
    shouldRefetch: shouldRefetch,
  };
};

export default useBrandContents;
