import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/common/firebase/firebase";
import { AccountDTO } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useGetAccounts = ({ officeId, userId }) => {
  const [accounts, setAccountss] = useState<AccountDTO[]>([]);
  const [shouldRefetchGet, refetchGet] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const userRole = user ? user["roles"][0]["role"]["name"] : null;

  const UserGetAccountsComponent = () => {
    useEffect(() => {
      if (user && storedIdToken && userRole !== "customer") {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersAccountsGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setAccountss(response.data);
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, shouldRefetchGet]);

    return null;
  };

  UserGetAccountsComponent();

  return {
    accounts: accounts,
    shouldRefetchGet: shouldRefetchGet,
    refetchGet,
  };
};

export default useGetAccounts;
