import React, { useState, useEffect, useLayoutEffect } from "react";
import { db, logout, auth } from "../components/common/firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  StyledComponent,
  getStoredProps,
} from "../components/common/change-themes/styles-component";
import ProfileIcon from "../images/home/profile-icon.svg";
import LogoDosh from "../images/logo/dosh-logo.svg";
import { Menu, Transition } from "@headlessui/react";
import { CUSTOMER_TABS, PAGE_URL } from "../constants/constants";
import Spinner from "../components/common/spinner/Spinner";
import useUserInfoTK from "../hooks/useUserInfoTK";
import useBrandInfo from "../hooks/useBrandInfo";
import Modal from "../components/common/modal/Modal";
import Button from "../components/common/button/Button";
import { getLocalStorageElement } from "../utils/utils";
import useUserInfo from "../hooks/useUserInfo";
import { useSelector } from "react-redux";
import { resetSelector, userSelector } from "../store/selectors/authSelector";
import { useDispatch } from "react-redux";
import { removeAccessToken } from "../store/actions/authenticate-actions";

const Layout = (props) => {
  const storedProps = getStoredProps();
  const storedIdToken = getLocalStorageElement("idToken");
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState(false);
  const param = useParams();
  const { role, brandOfficeId, userName, userId, emailAddress } =
    useUserInfoTK(storedIdToken);
  let officeId = parseInt(param.officeid);
  const loginToken = useSelector(userSelector);
  const { brandInfo, officeName, id } = useBrandInfo({
    brandOfficeId: param.officeid,
  });
  const [accessible, setAccessible] = useState(true);
  const [checkingAccess, setCheckingAccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (loginToken === "" && storedIdToken === "") {
      return navigate(
        PAGE_URL.LOGIN.replace(":brand", param.brand)
          .replace(":brandid", param.brandid)
          .replace(":office", param.office)
          .replace(":officeid", param.officeid)
      );
    }
  }, [loginToken, storedIdToken]);

  useEffect(() => {
    if (storedIdToken && brandInfo !== undefined) {
      if (parseInt(param.brandid) !== brandInfo.id) {
        setAccessible(false);
      } else {
        if (officeId !== brandOfficeId && brandOfficeId !== 0) {
          setAccessible(false);
        } else setAccessible(true);
      }
    }
  }, [storedIdToken, brandInfo]);

  const onReturnHome = () => {
    try {
      navigate(
        PAGE_URL.CUSTOMERS.replace(":brand", param.brand)
          .replace(":brandid", param.brandid)
          .replace(":office", param.office)
          .replace(":officeid", param.officeid)
      );
    } catch (err) {}
  };

  const onSubmitRecall = () => {
    localStorage.setItem("idToken", "");
    localStorage.setItem("reFreshToken", "");
    dispatch(removeAccessToken());
    logout();
  };

  const closeErrorModal = () => {
    localStorage.setItem("idToken", "");
    localStorage.setItem("reFreshToken", "");
    dispatch(removeAccessToken());
    return navigate("/");
  };

  return (
    <StyledComponent
      topLeftColor={storedProps.topLeftColor}
      bottomRightColor={storedProps.bottomRightColor}
    >
      <div className="flex justify-between ">
        {role === "office-manager" ||
        role === "broker" ||
        role === "brand-manager" ? (
          <button className="max-w-[200px] p-3" onClick={() => onReturnHome()}>
            <img src={storedProps.logoUrl ?? LogoDosh} alt="Logo" />
          </button>
        ) : (
          <button className="max-w-[200px] p-3">
            <img src={storedProps.logoUrl ?? LogoDosh} alt="Logo" />
          </button>
        )}
        <div className="flex items-center justify-center p-3">
          <div className="relative inline-block text-left">
            <Menu>
              {({ open }) => (
                <>
                  <span className="rounded-md ">
                    <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out rounded-md focus:outline-none">
                      <div className="flex">
                        <img
                          className="w-5 h-5 ml-2 mr-1"
                          src={ProfileIcon}
                          alt=""
                        />
                        <span className="text-sm font-medium leading-5 truncate">
                          {emailAddress
                            ? emailAddress
                            : userName
                            ? userName
                            : ""}
                        </span>
                      </div>
                      <svg
                        className="w-5 h-5 ml-2 -mr-1"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Menu.Button>
                  </span>

                  <Transition
                    show={open}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute right-4 w-max mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-500 rounded-md shadow-lg outline-none"
                    >
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#sign-out"
                              className={`${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700"
                              } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left no-underline`}
                              onClick={() => onSubmitRecall()}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <div className="min-h-[85vh] min-w-[100vh]">
        <Outlet />
      </div>
      <div className="flex justify-center px-3 pb-5">
        <span className="cursor-pointer no-underline text-sm text-white dark:text-blue-500 font-semibold">
          Powered by diñeiro
        </span>
      </div>
      {spinner && <Spinner></Spinner>}
      <Modal
        show={!accessible}
        toggle={closeErrorModal}
        content={
          <div className=" rounded-xl bg-gray-200 px-4 py-4">
            <div className="flex flex-col items-center">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="mt-2 text-center text-black">
                <p>You do not have permission to access this brand or office</p>
              </div>
              <div className="flex justify-center gap-4 mt-4">
                <Button
                  px="6px"
                  borderRadius="8px"
                  h="30px"
                  w="60px"
                  onClick={() => closeErrorModal()}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        }
      ></Modal>
    </StyledComponent>
  );
};

export default Layout;
