import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/common/firebase/firebase";
import { getBrandOfficesApi, getUserApi } from "../services/api-service";
import { BrandDTO, BrandOfficeDTO } from "@dineiro/dineiro-sdk-mobile";

const useBrandInfo = ({ brandOfficeId }) => {
  const [officeName, setOfficeName] = useState("");
  const [id, setId] = useState(0);
  const [brandInfo, setBrand] = useState<BrandDTO>();

  const BrandInfoComponent = () => {
    useEffect(() => {
      getInfo();
    }, [brandOfficeId]);

    return null;
  };
  const getInfo = async () => {
    const brandApi = getBrandOfficesApi("");

    try {
      const response = await brandApi.brandOfficeGet({
        id: brandOfficeId,
      });
      setId(response.data?.id);
      setOfficeName(response.data?.name);
      setBrand(response.data?.brand);
    } catch (err) {
      console.log(err);
    }
  };
  BrandInfoComponent();

  return {
    id: id,
    officeName,
    brandInfo,
  };
};

export default useBrandInfo;
