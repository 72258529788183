import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Talk from "talkjs";
import { auth } from "../common/firebase/firebase";
import useUserInfoTK from "../../hooks/useUserInfoTK";
import { Session, Chatbox, Inbox } from "@talkjs/react";
import { generateRandomCode } from "../../utils/utils";
import Modal from "../common/modal/Modal";
import Button from "../common/button/Button";
import useModal from "../../hooks/useModal";
import { useForm } from "react-hook-form";
import { UserDTO } from "@dineiro/dineiro-sdk-mobile";
import toast from "react-hot-toast";
import { StyledCheckBox } from "../common/change-themes/styles-component";

const MyChat: React.FC<{
  users: UserDTO[];
}> = ({ users }) => {
  const { userId, userName, emailAddress } = useUserInfoTK();
  const [conversationId, setConversationId] = useState("");
  const [conversationSubject, setConversationSubject] = useState("");
  const [conversation, setConversation] = useState();
  const [titleChanged, setTitleChanged] = useState("");
  const [updated, setUpdated] = useState(true);
  const [participantUsers, setParticipantUsers] = useState<UserDTO[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const { show: showChangeModal, toggle: toggleChangeModal } = useModal();
  const { show: showCreateChatModal, toggle: toggleCreateChatModal } =
    useModal();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const allSelected = watch("participants", []);

  const syncUser = useCallback(() => {
    if (userId) {
      return new Talk.User({
        id: userId,
        name: userName,
        email: emailAddress,
        welcomeMessage: "Hi!",
        role: "default",
      });
    }
    return new Talk.User({
      id: "Temple",
      name: "userName",
      email: "emailAddress",
      welcomeMessage: "Hi!",
      role: "default",
    });
  }, [userId]);

  function getFormData(data) {
    const conversationTitle = data.conversationTitle;
    updateTitle(conversationTitle);
  }

  const updateTitle = (title) => {
    setTitleChanged(title);
    setUpdated(!updated);
    reset();
    toggleChangeModal();
    // const data = {
    //   subject: title ? title : undefined,
    // };
    // return (
    //   fetch(
    //     `https://api.talkjs.com/v1/cKPPHrUM/conversations/${conversationId}`,
    //     {
    //       method: "put",
    //       headers: {
    //         Authorization: `Bearer sk_live_1Gecujw4kFPkZgFHFzmjMlIG77Snx5d7`,
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify(data),
    //     }
    //   )
    //     // We get the API response and receive data in JSON format
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log(data);
    //       toggleChangeModal();
    //     })
    //     .catch((error) => console.error(error))
    // );
  };

  const syncConversation = useCallback(
    (session) => {
      if (titleChanged === "") {
        const participants = [
          session.me,
          ...participantUsers.map((user) => {
            return new Talk.User({
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              role: "default",
            });
          }),
        ];
        const id = `${userId}${
          participantUsers[0] && participantUsers[0].id
            ? participantUsers[0].id
            : 0
        }${generateRandomCode()}`;

        const conversation = session.getOrCreateConversation(id);

        participants.forEach((participant) => {
          conversation.setParticipant(participant);
        });
        if (conversationSubject !== "")
          conversation.setAttributes({ subject: conversationSubject });
        return conversation;
      } else {
        const conversation = session.getOrCreateConversation(conversationId);
        if (titleChanged !== "")
          conversation.setAttributes({ subject: titleChanged });
        setConversationId("");
        setTitleChanged("");
        return conversation;
      }
    },
    [participantUsers, updated]
  );

  function createNewChat(data) {
    try {
      setupDataConservation(data);
      toast.success("Conservation addition successful.");
    } catch (e) {
      toast.error(e.message);
    } finally {
      reset();
      toggleCreateChatModal();
    }
  }

  const setupDataConservation = (data) => {
    if (data.subject !== "") {
      setConversationSubject(data.subject);
    }
    if (data.participants.length > 0) {
      let participantsData = users.filter((user) =>
        data.participants.some((data) => data === user.id)
      );
      setParticipantUsers(participantsData);
    }
  };

  const handleSelectAll = () => {
    setSelectedParticipants(users.map((user) => user.id));
    setValue(
      "participants",
      users.map((user) => user.id) // Set all user IDs as selected values
    );
  };

  const handleClearSelect = () => {
    setSelectedParticipants([]);
    setValue("participants", []); // Set giá trị 'participants' thành mảng rỗng
  };

  function updateSelectedParticipants(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option: HTMLOptionElement) => option.value
    );

    setSelectedParticipants(selectedOptions);
  }

  return (
    <div className="flex justify-center">
      <Session appId="tkefd9RK" syncUser={syncUser}>
        <div className="relative">
          <div className="w-[835px] absolute max-w-[840px] h-[700px] overflow-hidden">
            <Inbox
              syncConversation={syncConversation}
              style={{
                width: "100%",
                maxWidth: 840,
                height: 700,
                borderRadius: "14px",
              }}
              onCustomConversationAction={(e) => {
                if (e.action == "editTitleOrImage") {
                  toggleChangeModal();
                  setConversationId(e.conversation.id);
                } else if (e.action == "createNewChat") {
                  toggleCreateChatModal();
                }
              }}
            />
          </div>
          <div className="flex gap-[15px]">
            <div className="w-[400px] max-w-[420px] h-[700px] bg-white/20 rounded-xl mx-auto overflow-hidden"></div>
            <div className="w-[420px] max-w-[450px] h-[638px] bg-white/20 rounded-b-xl mx-auto mt-auto overflow-hidden"></div>
          </div>
        </div>
      </Session>
      <Modal
        show={showChangeModal}
        toggle={toggleChangeModal}
        content={
          <div className="rounded-xl bg-gray-200 px-2 py-4">
            <div className="flex flex-col items-center">
              <div className="w-full">
                <form
                  onSubmit={handleSubmit(getFormData)}
                  className="flex justify-center items-center gap-2"
                >
                  <div className="w-2/3 mb-4">
                    <label
                      className=" font-semibold"
                      htmlFor="conversationTitle"
                    >
                      New Conversation title:
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-md"
                      id="conversationTitle"
                      placeholder="Title"
                      required
                      {...register("conversationTitle")}
                    />
                  </div>
                  <div className="justify-center">
                    <Button
                      w="100px"
                      h="40px"
                      px="16px "
                      py="8px"
                      borderRadius="0.375rem"
                      fontSize="12px"
                      lineHeight="16px"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        }
      ></Modal>
      <Modal
        show={showCreateChatModal}
        toggle={toggleCreateChatModal}
        content={
          <div className=" rounded-xl bg-gray-200 px-2 py-4">
            <div className="flex flex-col items-center">
              <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative rounded-lg dark:bg-white-200">
                  <div className="flex items-center justify-center border-b rounded-t dark:border-gray-400">
                    <h3 className="text-lg font-semibold text-gray-900">
                      Create new chat
                    </h3>
                  </div>
                  <form onSubmit={handleSubmit(createNewChat)}>
                    <div className="grid gap-4 mb-4 grid-cols-2">
                      <div className="col-span-2">
                        <label
                          htmlFor="participants"
                          className="block mb-2 font-bold text-gray-900"
                        >
                          Participants
                        </label>
                        <div className="flex justify-between py-2 mt-2 text-sm">
                          <div className="flex items-center">
                            <button
                              type="button"
                              className="underline text-sm"
                              onClick={handleSelectAll}
                              disabled={allSelected.length === users.length} // Disable if all selected
                            >
                              Select All
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="underline text-sm"
                              onClick={handleClearSelect}
                            >
                              Clear Select
                            </button>
                          </div>
                        </div>

                        <div className=" my-2">
                          {selectedParticipants.length === users.length ? (
                            <input
                              type="text"
                              readOnly
                              className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-white-600 dark:border-gray-500 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              value="All customers"
                            />
                          ) : (
                            <input
                              type="text"
                              readOnly
                              className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-white-600 dark:border-gray-500 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              value={selectedParticipants
                                .map((selectedId) => {
                                  const selectedCustomer = users.find(
                                    (customer) => customer.id === selectedId
                                  );
                                  return `${selectedCustomer.firstName} ${selectedCustomer.lastName}`;
                                })
                                .join(", ")}
                            />
                          )}
                        </div>

                        <select
                          id="participants"
                          name="participants"
                          multiple
                          required
                          className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-white-600 dark:border-gray-500 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500 h-[250px]"
                          {...register("participants")}
                          onChange={updateSelectedParticipants}
                        >
                          {users.map((customer) => (
                            <option value={customer.id} key={customer.id}>
                              {customer.firstName} {customer.lastName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="subject"
                          className="block mb-2 font-bold text-gray-900"
                        >
                          Conservation title
                        </label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-600 dark:border-gray-500 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Add a subject (optional)"
                          {...register("subject")}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <Button
                        w="250px"
                        h="40px"
                        px="16px "
                        py="8px"
                        borderRadius="0.375rem"
                        fontSize="12px"
                        lineHeight="16px"
                      >
                        Start conversation
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }
      ></Modal>
    </div>
  );
};

export default MyChat;
