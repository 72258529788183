import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Button from "../common/button/Button";
import ExportIcon from "../../images/home/export-icon.svg";
import { useParams } from "react-router-dom";
import * as FileSaver from "file-saver";
import { AccountDTO, CONTAINEREnum, Period } from "@dineiro/dineiro-sdk-mobile";
import { formatDate } from "../../utils/utils";
import useUserProperties from "../../hooks/useUserProperties";
import useGetAccounts from "../../hooks/useGetAccounts";
import useHoldings from "../../hooks/useHoldings";
import useUserSuperAccounts from "../../hooks/useUserSuperAccounts";
import useUserExpenses from "../../hooks/useUserExpenses";

interface DataSheet {
  name: string;
  price: string;
}

interface AssetDataSheet {
  title: string;
  name: string;
  price: string;
}

const ExportUserSumerize = ({ customerName, id }) => {
  const [loans, setLoans] = useState<AccountDTO[]>([]);
  const [investments, setInvestments] = useState([]);
  const [expensesTotal, setExpensesTotal] = useState(0);
  const [assetsTotal, setAssetsTotal] = useState(0);
  const [liabilitiesTotal, setLiabilitiesTotal] = useState(0);
  const [expensesData, setExpenses] = useState<DataSheet[]>([]);
  const [liabilitiesData, setLiabilities] = useState<DataSheet[]>([]);
  const [assetsData, setAssets] = useState<AssetDataSheet[]>([]);
  const { officeid, tab } = useParams();
  const [spinner, setSpinner] = useState(false);

  const { properties, banks, vehicles } = useUserProperties({
    officeId: officeid,
    userId: id,
  });

  const { accounts, shouldRefetchGet, refetchGet } = useGetAccounts({
    officeId: officeid,
    userId: id,
  });
  const { holdings } = useHoldings({
    officeId: officeid,
    userId: id,
    accounts: accounts,
  });
  const { supperAccounts } = useUserSuperAccounts({
    officeId: officeid,
    userId: id,
  });
  const { expenses } = useUserExpenses({
    officeId: officeid,
    userId: id,
    period: Period.ThirtyDays,
  });

  const getLoans = () => {
    if (accounts.length > 0) {
      const loans = accounts.filter(
        (account) =>
          account.container === CONTAINEREnum.Loan ||
          account.container === CONTAINEREnum.OtherLiabilities ||
          account.container === CONTAINEREnum.CreditCard
      );
      setLoans(loans);
    }
  };

  const getExpensesTotal = () => {
    if (expenses.length > 0) {
      const totalLoans = expenses.reduce(
        (acc, obj) => acc + obj.amount.amount!,
        0
      );
      setExpensesTotal(totalLoans);
    }
  };
  const getAssetTotal = () => {
    const totalProperties = properties.reduce(
      (acc, obj) => acc + obj.estimate?.amount!,
      0
    );
    const totalBanks = banks.reduce(
      (acc, obj) => acc + obj.balance?.amount!,
      0
    );
    const totalVehicles = vehicles.reduce(
      (acc, obj) => acc + obj.estimationPrice?.amount!,
      0
    );
    const totalShares = holdings.reduce((acc, obj) => acc + obj.amount!, 0);
    const totalSupperAccounts = supperAccounts.reduce(
      (acc, obj) => acc + obj.balance?.amount!,
      0
    );

    const totalInvestments = investments.reduce(
      (acc, obj) => acc + obj.balance?.amount!,
      0
    );

    const totalAssets =
      totalProperties +
      totalBanks +
      totalVehicles +
      totalShares +
      totalSupperAccounts +
      totalInvestments;
    setAssetsTotal(totalAssets);
  };

  useEffect(() => {
    setInvestments(
      accounts.filter(
        (account) =>
          account.container === CONTAINEREnum.Investment &&
          !account.accountType.includes("SUPER_ANNUATION")
      )
    );
  }, [accounts]);

  const getTotal = () => {
    if (loans.length > 0) {
      const totalLoans = loans.reduce(
        (acc, obj) => (acc + obj.balance?.amount! ? obj.balance?.amount! : 0),
        0
      );
      setLiabilitiesTotal(totalLoans);
    }
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportDate = new Date();
  const [fileName, setFileName] = useState("");
  const [dayTotals, setDayTotals] = useState("");

  const convertData = () => {
    if (expenses.length > 0) {
      setExpenses(
        expenses.map((expense) => {
          return {
            name: expense.name,
            price: expense.amount.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          };
        })
      );
    }
    if (accounts.length > 0) {
      setAssets([]);
      properties.length > 0 ??
        setAssets((prevAssets) => [
          ...prevAssets,
          ...properties.map<AssetDataSheet>((property) => ({
            title: "PROPERTIES",
            name: property.address,
            price: property.estimate.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          })),
        ]);
      banks.length > 0 ??
        setAssets((prevReportStatus) => [
          ...prevReportStatus,
          ...banks.map<AssetDataSheet>((bank) => ({
            title: "BANKS",
            name: bank.providerName,
            price: bank.balance.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          })),
        ]);
      vehicles.length > 0 ??
        setAssets((prevReportStatus) => [
          ...prevReportStatus,
          ...vehicles.map((vehicle) => ({
            title: "VEHICLES",
            name: vehicle.description,
            price: vehicle.estimationPrice.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          })),
        ]);
      holdings.length > 0 ??
        setAssets((prevReportStatus) => [
          ...prevReportStatus,
          ...holdings.map<AssetDataSheet>((holding) => ({
            title: "SHARES",
            name: holding.title,
            price: holding.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          })),
        ]);
      investments.length > 0 ??
        setAssets((prevReportStatus) => [
          ...prevReportStatus,
          ...investments.map<AssetDataSheet>((property) => ({
            title: "INVESTMENTS",
            name: property.address,
            price: property.estimate.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          })),
        ]);
    }
    if (loans.length > 0) {
      setLiabilities(
        loans.map((account) => {
          return {
            name: account.providerName,
            price:
              account.balance! && account.balance.amount!
                ? account.balance.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : "0",
          };
        })
      );
    }
  };

  const getFileName = () => {
    setFileName(`${formatDate(exportDate)}_${customerName}_sumerize`);
    setDayTotals("30 days");
  };

  const getSheet = (ws) => {
    return {
      Sheets: { user_sumerize: ws },
      SheetNames: ["user_sumerize"],
    };
  };

  useEffect(() => {
    getExpensesTotal();
    getAssetTotal();
    getLoans();
    getTotal();
  }, [
    expenses,
    properties,
    banks,
    vehicles,
    supperAccounts,
    holdings,
    investments,
    accounts,
  ]);
  useEffect(() => {
    convertData();
    getFileName();
  }, [expenses]);

  const exportToCSV = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      ["Customer Name", "", customerName],
      ["Day total", "", dayTotals],
      ["Report date", "", formatDate(exportDate)],
      ["", ""],
      ["EXPENSES:"],
    ]);

    ws["!cols"] = [{ width: 20 }, { width: 20 }, { width: 20 }];
    XLSX.utils.sheet_add_json(ws, expensesData, {
      origin: -1,
      skipHeader: true,
    });
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "TotalExpense",
          "",
          expensesTotal.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
        ],
        ["", ""],
        ["ASSETS", ""],
      ],
      {
        origin: -1,
      }
    );
    XLSX.utils.sheet_add_json(ws, assetsData, { origin: -1, skipHeader: true });
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "TotalAsset",
          "",
          assetsTotal.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
        ],
        ["", ""],
        ["LIABILITIES", ""],
      ],
      {
        origin: -1,
      }
    );
    XLSX.utils.sheet_add_json(ws, liabilitiesData, {
      origin: -1,
      skipHeader: true,
    });
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "TotalLiabilities",
          "",
          liabilitiesTotal.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
        ],
      ],
      {
        origin: -1,
      }
    );
    const wb = getSheet(ws);
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="flex items-center justify-center space-x-1"
      onClick={() => exportToCSV()}
    >
      <img className="w-5 h-5" src={ExportIcon} alt="" />
      <span>Export</span>
    </button>
  );
};

export default ExportUserSumerize;
