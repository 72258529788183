import React, { useState, useEffect } from "react";
import {
  PropertyDTO,
  PropertyTypeEnum,
  SavingGoalDTO,
} from "@dineiro/dineiro-sdk-mobile";
import GoalSavingComponent from "../../components/goals-insights/GoalSavingComponent";
import OwnedProperty from "../../components/goals-insights/OwnedProperty";
import MortgagedProperty from "../../components/goals-insights/MortgagedProperty";

const GoalAndInsightsTab: React.FC<{
  savingGoals: SavingGoalDTO[];
  properties: PropertyDTO[];
}> = ({ savingGoals, properties }) => {
  const [ownedProperties, setOwnedProperties] = useState([]);
  const [mortgagedProperties, setMortgagedProperties] = useState([]);

  useEffect(() => {
    if (properties.length > 0) {
      const listOwned = [];
      const listMortgaged = [];
      properties.map((property) => {
        if (property.type === PropertyTypeEnum.Owned) {
          listOwned.push(property);
        }
        if (property.type === PropertyTypeEnum.Financed) {
          listMortgaged.push(property);
        }
      });
      setOwnedProperties(listOwned);
      setMortgagedProperties(listMortgaged);
    }
  }, [properties]);

  // const handleCheckboxChange = (itemId: number) => {
  //   const updatedItems = mortgagedProperties.map((item) =>
  //     item.id === itemId
  //       ? { ...item, isNotification: !item.isNotification }
  //       : item
  //   );

  //   setMortgagedProperties(updatedItems);
  // };

  return (
    <div>
      <div className=" my-10">
        <div className="flex justify-center text-sm font-semibold">
          <h3 className=" text-3xl header-h3 font-bold">Goal Savings</h3>
        </div>
        {savingGoals.length > 0 ? (
          savingGoals.map((item, index) => {
            return (
              <GoalSavingComponent
                key={index}
                item={item}
              ></GoalSavingComponent>
            );
          })
        ) : (
          <div className="flex justify-center my-10 text-[15px]">
            This user does not have any savings goals.
          </div>
        )}
      </div>
      <div className=" mt-48 ">
        <div className="flex justify-center text-sm font-semibold">
          <h3 className=" text-3xl header-h3 font-bold">Property Insights</h3>
        </div>
        <div className="mb-5 py-2">
          {ownedProperties.length > 0 || mortgagedProperties.length > 0 ? (
            <div className="p-2 space-y-28">
              <OwnedProperty ownedProperties={ownedProperties}></OwnedProperty>
              <div className="mb-2 space-y-5">
                <span className="text-2xl font-[700] leading-5">
                  Mortgaged Properties
                </span>
                {mortgagedProperties.map((item) => {
                  return (
                    <MortgagedProperty
                      key={item.id}
                      mortgagedProperty={item}
                    ></MortgagedProperty>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="flex justify-center my-10 text-[15px]">
              This user does not have any savings goals.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoalAndInsightsTab;
