import React, { useEffect } from "react";
import "./spinner.scss";

const Spinner = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="overlay dark:bg-gray-950 ">
      <div className="flex space-x-2 justify-center items-center w-full h-screen dark:invert ">
        <span className="sr-only">Loading...</span>
        <div className="h-3 w-3 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
        <div className="h-3 w-3 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div className="h-3 w-3 bg-gray-400 rounded-full animate-bounce"></div>
      </div>
    </div>
  );
};

export default Spinner;
