import "../../styles.scss";
import LogoImage from "../../images/logo/dosh-logo.svg";
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PAGE_URL } from "../../constants/constants";
import { useAuthState } from "react-firebase-hooks/auth";
import Spinner from "../../components/common/spinner/Spinner";
import useBrandInfo from "../../hooks/useBrandInfo";
import useUserInfoTK from "../../hooks/useUserInfoTK";
import {
  StyledComponent,
  getStoredProps,
} from "../../components/common/change-themes/styles-component";
import Button from "../../components/common/button/Button";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/selectors/authSelector";
import toast, { Toaster } from "react-hot-toast";
import { getBrandOfficesApi } from "../../services/api-service";
import Cookies from "js-cookie";

const Verify = () => {
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const loginToken = useSelector(userSelector);
  const [isLoading, setIsLoading] = useState(true);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const { brand, brandid, office, officeid } = useParams();
  const { role, brandOfficeId } = useUserInfoTK(loginToken);
  const storedProps = getStoredProps();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      const storedProp = localStorage.getItem("brandOfficeName");
      if (storedProp) document.title = `Verify - ${storedProp}`;
      setSpinner(false);
    }, 1500);
    if (!loginToken && isLoading) {
      return navigate(
        PAGE_URL.LOGIN.replace(":brand", brand)
          .replace(":brandid", brandid)
          .replace(":office", office)
          .replace(":officeid", officeid)
      );
    }
  }, [loginToken]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "") {
      const nextIndex = Math.min(index + 1, inputRefs.current.length - 1);
      inputRefs.current[nextIndex]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const onLogin = (e) => {
    setIsLoading(false);
    const otpString = otp.join("");
    const brandApi = getBrandOfficesApi("");
    toast.promise(
      brandApi
        .brandOfficeOtpVerifyPost({
          id: parseInt(officeid),
          brandOfficeOtpVerifyPostViewModel: {
            idToken: loginToken,
            code: otpString,
          },
        })
        .then((response) => {
          localStorage.setItem("idToken", response.idToken);
          localStorage.setItem("reFreshToken", response.refreshToken);
        })
        .then(() => {
          if (brand) {
            navigate(
              PAGE_URL.HOME.replace(
                ":brand",
                brand.toLowerCase().replace(/\s/g, "-")
              )
                .replace(":brandid", brandid.toString())
                .replace(":office", office.toLowerCase().replace(/\s/g, "-"))
                .replace(":officeid", officeid.toString())
            );
          }
        }),
      {
        loading: "Verifying...",
        success: <b>Verify OPT successful.</b>,
        error: <b>Verify OPT failed. Please try again!</b>,
      }
    );
  };

  return (
    <StyledComponent className="min-h-screen flex flex-col justify-center items-center">
      <div className=" flex justify-center mb-20">
        <img src={storedProps.logoUrl ?? LogoImage} alt="Logo" />
      </div>
      <form onSubmit={handleSubmit(onLogin)}>
        <div className="flex flex-col items-center justify-center">
          <span>Please enter the 6 digit PIN sent to your email.</span>
          <div className="rounded text-center p-2">
            <div
              id="otp"
              className="flex flex-row justify-center text-center mt-2"
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className="m-1 border-white/40 h-16 max-w-[55px] text-center rounded verify-input bg-black/20 w-full min-h-[44px] text-white placeholder-gray-200 dark:text-white focus:border-white focus:ring-0"
                  type="text"
                  value={digit}
                  maxLength={1}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onChange={(e) => handleChange(e, index)}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center my-4">
          <Button
            w="76px"
            h="40px"
            px="16px "
            py="8px"
            borderRadius="0.375rem"
            fontSize="12px"
            lineHeight="16px"
          >
            LOGIN
          </Button>
        </div>
      </form>
      <div className="flex justify-center p-2 absolute bottom-0">
        <span className="bottom-0 cursor-pointer no-underline text-sm text-white dark:text-blue-500 font-semibold">
          Powered by diñeiro
        </span>
      </div>
      {spinner && <Spinner></Spinner>}
      <Toaster position="top-center" reverseOrder={false} />
    </StyledComponent>
  );
};

export default Verify;
