import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/common/firebase/firebase";
import { getBrandOfficesApi } from "../services/api-service";
import { SavingGoalDTO } from "@dineiro/dineiro-sdk-mobile";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useSavingGoals = ({ officeId, userId }) => {
  const [savingGoals, setSavingGoals] = useState<SavingGoalDTO[]>([]);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const userRole = user ? user["roles"][0]["role"]["name"] : null;

  const UserSavingGoalsComponent = () => {
    useEffect(() => {
      if (user && storedIdToken && userRole !== "customer") {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersSavingGoalsGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setSavingGoals(response.data);
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId]);

    return null;
  };

  UserSavingGoalsComponent();

  return {
    savingGoals: savingGoals,
  };
};

export default useSavingGoals;
