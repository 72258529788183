export const environment = process.env.REACT_APP_PRODUCTION;
export const isDeveloper = process.env.REACT_APP_IS_DEVELOPER ?? false;
export const dineiroApiUrl =
  environment === "dev"
    ? "https://api.sandbox.dineiro.app"
    : environment === "staging"
    ? "https://api.stag.doshapp.com.au"
    : environment === "production"
    ? "https://api.doshapp.com.au"
    : null;

export const keyFreeHost = "397d753ebc18d6e902448c9293de170d";

export const FirebaseConfig =
  environment === "production"
    ? {
        apiKey: "AIzaSyAbNb8BddcJrtZkwtUi1KTQxTd6nh3Z_WY",
        authDomain: "dineirov2.firebaseapp.com",
        projectId: "dineirov2",
        storageBucket: "dineirov2.appspot.com",
        messagingSenderId: "45226201762",
        appId: "1:45226201762:web:7098a5100ba4b2db2ffdfb",
        measurementId: "G-QXCS94MSFF",
      }
    : environment === "staging"
    ? {
        apiKey: "AIzaSyDsuBnzHnGdRtyMaJarnoi51QXdcHhOnDA",
        authDomain: "dineirov2testing.firebaseapp.com",
        projectId: "dineirov2testing",
        storageBucket: "dineirov2testing.appspot.com",
        messagingSenderId: "2812036012",
        appId: "1:2812036012:web:bb6fadfb3c3ac2b393b009",
        measurementId: "G-BN65LCJ43Q",
      }
    : environment === "dev"
    ? {
        apiKey: "AIzaSyCW8ot--fwX7CkXvQywfBgc3DBMslI8hTQ",
        authDomain: "dineiro-sandbox.firebaseapp.com",
        projectId: "dineiro-sandbox",
        storageBucket: "dineiro-sandbox.appspot.com",
        messagingSenderId: "394856641993",
        appId: "1:394856641993:web:92292e64c97d8347c92418",
      }
    : null;
