import React, { useEffect, useState } from "react";
import "../../styles.scss";
import LogoImage from "../../images/logo/dosh-logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { PAGE_URL, VALIDATION } from "../../constants/constants";
import { useForm } from "react-hook-form";
import Spinner from "../../components/common/spinner/Spinner";
import {
  StyledComponent,
  getStoredProps,
} from "../../components/common/change-themes/styles-component";
import Button from "../../components/common/button/Button";

const ResetPassword = () => {
  const { brand, brandid, office, officeid } = useParams();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [failed, setFailed] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const storedProps = getStoredProps();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setFailed(false);
    setSpinner(true);
  };

  const onLogin = () => {
    setSpinner(true);
    setTimeout(async () => {
      try {
        navigate(
          PAGE_URL.LOGIN.replace(":brand", brand)
            .replace(":brandid", brandid)
            .replace(":office", office)
            .replace(":officeid", officeid)
        );
      } catch (err) {
        setFailed(true);
      } finally {
        setSpinner(false);
      }
    }, 1500);
  };

  useEffect(() => {
    document.title = `Forgot Password`;
  });

  return (
    <StyledComponent className=" min-h-screen flex flex-col justify-center items-center">
      <div className=" flex justify-center mb-20">
        <img src={storedProps.logoUrl ?? LogoImage} alt="Logo" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col space-y-2 items-center w-[300px]">
          <div className="w-full flex-col">
            <input
              type="email"
              id="userName"
              className=" bg-black/20 w-full min-h-[44px] rounded text-white placeholder-gray-200 dark:text-white border-white/40 focus:border-white focus:ring-0"
              placeholder="Email"
              {...register("email", {
                required: VALIDATION.required.email,
                pattern: VALIDATION.email,
              })}
            />
            {errors.email && (
              <span className="text-red-400 text-sm font-bold">
                {errors.email.message.toString()}
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-2">
          <span
            className="cursor-pointer underline text-xs text-white dark:text-blue-500"
            onClick={() => onLogin()}
          >
            Click to login?
          </span>
        </div>
        <div className="flex justify-center my-4">
          <Button
            w="33.333333%"
            h="40px"
            px="16px "
            py="8px"
            borderRadius="0.375rem"
            fontSize="12px"
            lineHeight="16px"
          >
            SUBMIT
          </Button>
        </div>
      </form>
      <div className="flex justify-center p-2 absolute bottom-0">
        <span className="bottom-0 cursor-pointer no-underline text-sm text-white dark:text-blue-500 font-semibold">
          Powered by diñeiro
        </span>
      </div>
      {spinner && <Spinner></Spinner>}
    </StyledComponent>
  );
};

export default ResetPassword;
