import { Action } from "redux";
export const LOGIN_WITH_EMAIL_REQUEST = "LOGIN_WITH_EMAIL_REQUEST";
export const LOGIN_WITH_EMAIL_SUCCESS = "LOGIN_WITH_EMAIL_SUCCESS";
export const LOGIN_WITH_EMAIL_FAILURE = "LOGIN_WITH_EMAIL_FAILURE";
export const LOGIN_WITH_PHONE_REQUEST = "LOGIN_WITH_PHONE_REQUEST";
export const LOGIN_WITH_PHONE_SUCCESS = "LOGIN_WITH_PHONE_SUCCESS";
export const LOGIN_WITH_PHONE_FAILURE = "LOGIN_WITH_EMAIL_FAILURE";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

export interface ISignIn {
  officeId: string;
  email: string;
  password: string;
}
export interface ISignInAction extends Action {
  officeId: string;
  email: string;
  password: string;
}

export interface ISignInWithphone {
  phoneNumber: string;
}

export interface ISignInWithPhoneAction extends Action {
  phoneNumber: string;
}

export const loginWithEmailRequest = ({
  officeId,
  email,
  password,
}: ISignIn): ISignInAction => {
  return {
    type: LOGIN_WITH_EMAIL_REQUEST,
    officeId,
    email,
    password,
  };
};

export const loginWithEmailSuccess = (loginToken) => {
  return {
    type: LOGIN_WITH_EMAIL_SUCCESS,
    loginToken,
  };
};

export const loginWithEmailFailure = (error) => ({
  type: LOGIN_WITH_EMAIL_FAILURE,
  error: error,
});

export const loginWithPhoneRequest = ({
  phoneNumber,
}: ISignInWithphone): ISignInWithPhoneAction => {
  return {
    type: LOGIN_WITH_PHONE_REQUEST,
    phoneNumber,
  };
};

export const loginWithPhoneSuccess = (user) => {
  return {
    type: LOGIN_WITH_PHONE_SUCCESS,
    user,
  };
};

export const loginWithPhoneFailure = (error) => ({
  type: LOGIN_WITH_PHONE_FAILURE,
  error: error,
});

export const removeAccessToken = () => ({
  type: REMOVE_TOKEN,
});
