import React, { useState, useEffect } from "react";
import assetsData from "../../utils/mocks/assets-data.json";
import {
  AccountDTO,
  OverriddenAccountTypeValue,
} from "@dineiro/dineiro-sdk-mobile";
import AccountItemWithType from "../../components/common/account/AccountItemWithType";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../components/common/firebase/firebase";
import { getBrandOfficesApi } from "../../services/api-service";
import { getLocalStorageElement } from "../../utils/utils";

interface GroupedLoan {
  accountType: any;
  accounts: AccountDTO[];
}

const LiabilitiesTab: React.FC<{
  loans: AccountDTO[];
  setChangeTypes: React.Dispatch<React.SetStateAction<boolean>>;
  changeTypes: any;
}> = ({ loans, setChangeTypes, changeTypes }) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [groupedLoans, setGroupedLoans] = useState<GroupedLoan[]>([]);
  const [total, setTotal] = useState(0);
  const { officeid, tab, id, customername } = useParams();
  const groupLoans = () => {
    if (loans.length > 0) {
      const sortedAccounts = loans.sort((a, b) =>
        a.accountType.localeCompare(b.accountType)
      );

      const groupedAccounts = new Map<any, AccountDTO[]>();

      sortedAccounts.forEach((account) => {
        if (groupedAccounts.has(account.accountType)) {
          groupedAccounts.get(account.accountType).push(account);
        } else {
          groupedAccounts.set(account.accountType, [account]);
        }
      });
      let index = 0;
      const result = Array.from(groupedAccounts.entries()).map<GroupedLoan>(
        ([accountType, accountList]) => ({
          accountType,
          accounts: accountList,
        })
      );
      setGroupedLoans(result);
    }
  };

  const getTotal = () => {
    if (loans.length > 0) {
      const totalLoans = loans.reduce(
        (acc, obj) => (acc + obj.balance?.amount! ? obj.balance?.amount! : 0),
        0
      );
      setTotal(totalLoans);
    }
  };

  const onChangeTypes = (type, accountId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersAccountsAccountTypePatch({
          id: parseInt(officeid),
          userId: id,
          accountId: accountId,
          brandOfficeCustomersAccountsAccountTypePatchViewModel: {
            accountType: OverriddenAccountTypeValue[type],
          },
        })
        .then((respone) => {
          setChangeTypes(!changeTypes);
        }),
      {
        loading: "Saving...",
        success: <b>Change account type successful.</b>,
        error: <b>Failed to change type.</b>,
      }
    );
  };

  useEffect(() => {
    groupLoans();
    getTotal();
  }, [loans]);

  return (
    <>
      {groupedLoans && groupedLoans.length > 0 ? (
        <div className="my-10 text-[15px]">
          <div className="flex justify-center text-sm font-semibold">
            <span>Change Acount Type</span>
          </div>

          <div>
            <div className="p-2">
              {groupedLoans.map((loanGroup) => {
                return (
                  <div
                    className="mb-5 px-0"
                    key={groupedLoans.indexOf(loanGroup) + 1}
                  >
                    <div className="mb-2">
                      <span className=" uppercase font-semibold">
                        {loanGroup.accountType.replace(/_/g, " ")}
                      </span>
                    </div>
                    {loanGroup.accounts.map((loan) => {
                      return (
                        <AccountItemWithType
                          name={`${loan.providerName}-${loan.accountName} (
                            ${loan.accountNumber.slice(-4)} 
                            )`}
                          container={loan.container}
                          accountType={loan.accountType}
                          amount={
                            loan.balance! && loan.balance.amount!
                              ? loan.balance.amount
                              : 0
                          }
                          key={loan.id}
                          accountId={loan.id}
                          onChangeTypes={onChangeTypes}
                        ></AccountItemWithType>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className="mt-5 p-2">
              <li className="flex justify-between py-2 font-semibold border-t border-slate-400">
                <span>Total Liabilities</span>
                <span>
                  {total.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </li>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center my-10 text-[15px]">
          This user does not have any loans
        </div>
      )}
    </>
  );
};

export default LiabilitiesTab;
