import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/common/firebase/firebase";
import { Period, TransactionCategoryDTO } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useUserExpenses = ({ officeId, userId, period }) => {
  const [expenses, setExpenses] = useState<TransactionCategoryDTO[]>([]);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const userRole = user ? user["roles"][0]["role"]["name"] : null;

  const UserExpensesComponent = () => {
    useEffect(() => {
      if (user && storedIdToken && userRole !== "customer") {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersExpensesGet({
                id: officeId,
                userId: userId,
                period: period,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setExpenses(response.data);
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, period]);

    return null;
  };

  UserExpensesComponent();

  return {
    expenses: expenses,
  };
};

export default useUserExpenses;
