import { AnyAction } from "redux";
import {
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
} from "../actions/user-actions";

// export interface UserState {
//   status: undefined | "LOADING" | "LOADED" | "ERROR";
// }

export const initialState = {
  status: undefined,
};

const reducer = (state = initialState, action: AnyAction) => {
  switch (action?.type) {
    case CREATE_USER:
      return {
        ...state,
        status: "LOADING",
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        status: "LOADED",
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        status: "ERROR",
      };
    default:
      return state;
  }
};

export default reducer;
