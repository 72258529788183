import { createSelector } from "reselect";
import { RootState } from "../store";

const authRootReducer = (state: RootState) => state.auth;

export const resetSelector = createSelector(authRootReducer, (state) => null);

export const userSelector = createSelector(
  authRootReducer,
  (state) => state.loginToken
);

export const loginFailSelector = createSelector(
  authRootReducer,
  (state) => state.isSuccess
);
