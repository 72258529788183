import { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import useUserInfoTK from "../hooks/useUserInfoTK";
import Modal from "../components/common/modal/Modal";
import Button from "../components/common/button/Button";
import Spinner from "../components/common/spinner/Spinner";
import { logout } from "../components/common/firebase/firebase";
import { useDispatch } from "react-redux";
import { removeAccessToken } from "../store/actions/authenticate-actions";
import { getLocalStorageElement } from "../utils/utils";
import { PAGE_URL } from "../constants/constants";
import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";

export interface RoleBaseGuardProps {
  children: ReactNode;
}

const RefreshToken: FC<RoleBaseGuardProps> = ({ children }) => {
  const [spinner, setSpinner] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
    // const additionalMinutes = 55;
    // const additionalMilliseconds = additionalMinutes * 60 * 1000;
    if (user && storedIdToken) {
      const fetchData = async () => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        let exp = user["exp"];
        if (currentTimestamp >= exp) {
          alert("Your session has expired. Please log in again.");
          localStorage.setItem("idToken", "");
          localStorage.setItem("reFreshToken", "");
          dispatch(removeAccessToken());
          return param.brandid && param.officeid
            ? navigate(
                PAGE_URL.LOGIN.replace(":brand", param.brand)
                  .replace(":brandid", param.brandid)
                  .replace(":office", param.office)
                  .replace(":officeid", param.officeid)
              )
            : navigate("/");
        }
      };
      fetchData();
    } else {
      console.log("No current user.");
    }
  });

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      if (storedIdToken === "") {
        return navigate(
          PAGE_URL.LOGIN.replace(":brand", param.brand)
            .replace(":brandid", param.brandid)
            .replace(":office", param.office)
            .replace(":officeid", param.officeid)
        );
      }
      setSpinner(false);
    }, 1500);
  }, [storedIdToken]);
  return (
    <>
      <>{children}</>
    </>
  );
};

export default RefreshToken;
