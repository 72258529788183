import React, { useState, useEffect, useRef, useCallback } from "react";
import Spinner from "../common/spinner/Spinner";
import UserIcon from "./../../images/home/userIcon.svg";
import BuldIcon from "./../../images/home/buld.svg";
import DocumentIcon from "./../../images/home/document-icon.svg";
import ExportIcon from "./../../images/home/export-icon.svg";
import FinancialIcon from "./../../images/home/financial.svg";
import SentIcon from "./../../images/home/sent.svg";
import FilterIcon from "./../../images/home/filter-icon.svg";
import { PAGE_URL, CUSTOMER_TABS } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import {
  getLocalStorageElement,
  getUniqueStartCharacters,
  handleNavigate,
  isExpired,
  sortFullNames,
} from "../../utils/utils";
import Button from "../common/button/Button";
import { StyledTableHeader } from "../common/change-themes/styles-component";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../common/firebase/firebase";
import { getBrandOfficesApi } from "../../services/api-service";
import toast from "react-hot-toast";
import {
  BackgroundTaskDTO,
  BackgroundTaskStatus,
  BackgroundTaskType,
  UserDTO,
} from "@dineiro/dineiro-sdk-mobile";
import useUserInfoTK from "../../hooks/useUserInfoTK";
import ExportUserSumerize from "../export/ExportUserSumerize";

const Customers: React.FC<{
  data: UserDTO[];
  pageSize: number;
  alphabet: string[];
}> = ({ data, pageSize, alphabet }) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [sortedData, setSortedData] = useState<UserDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const loaderRef = useRef(null);
  const [reportStatus, setReportStatus] = useState<BackgroundTaskDTO[]>([]);
  const totalPageIndex = Math.ceil(sortedData.length / pageSize);
  const { role, brandOfficeId } = useUserInfoTK();
  const [displayedData, setDisplayedData] = useState(
    sortedData.slice(0, pageSize)
  );

  useEffect(() => {
    setSortedData(sortFullNames(data, isAscending));
  }, [data]);

  const fetchData = useCallback(() => {
    if (isLoading || index >= totalPageIndex) return;

    setIsLoading(true);

    try {
      const newData = sortedData.slice(
        (index + 1) * pageSize,
        (index + 2) * pageSize
      );
      setDisplayedData((prevData) => [...prevData, ...newData]);
      setIndex((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }, [sortedData, index, isLoading, pageSize, totalPageIndex]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchData();
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [fetchData]);

  useEffect(() => {
    setIndex(0);
    setDisplayedData(sortedData.slice(0, pageSize));
  }, [sortedData, pageSize]);

  const onAssetsTab = (customerName, id, tab) => {
    try {
      navigate(
        handleNavigate(
          PAGE_URL.CUSTOMER_ASSETS.replace(
            ":customername",
            customerName.replace(/\s/g, "-")
          )
            .replace(":id", id)
            .replace(":tab", tab.toLowerCase())
        )
      );
    } catch (err) {}
  };

  const handleSort = () => {
    setIsAscending(!isAscending);
    const newSortedData = sortFullNames(sortedData, !isAscending);
    setSortedData(newSortedData);
    setIndex(0);
    setDisplayedData(newSortedData.slice(0, pageSize));
  };

  const onOrder = (customerId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersBackgroundTasksPost({
          id: brandOfficeId,
          userId: customerId,
          brandOfficeCustomersBackgroundTasksPostViewModel: {
            type: BackgroundTaskType.GenerateUserAnnualReport,
          },
        })
        .then((respone) => {
          setTimeout(() => {
            setReportStatus((prevReportStatus) => [
              ...prevReportStatus,
              respone.data,
            ]);
          }, 1000);
        }),
      {
        loading: "Saving...",
        success: <b>Order report successful.</b>,
        error: <b>Failed to order report.</b>,
      }
    );
  };

  const isOutOfDateReport = (
    backgroundProcesses: BackgroundTaskDTO[],
    customer
  ) => {
    if (
      backgroundProcesses.length === 0 &&
      !reportStatus.some((status) => status.userId === customer.id)
    )
      return true;

    if (backgroundProcesses.length > 0) {
      const customerStatus = backgroundProcesses?.some(
        (process) =>
          process.status === BackgroundTaskStatus.Canceled &&
          process.type === BackgroundTaskType.GenerateUserAnnualReport
      );
      if (customerStatus) return true;
      const latestReport = backgroundProcesses.sort((a, b) => b.id - a.id)[0];
      if (isExpired(latestReport.createdAt)) return true;
    }
    return false;
  };

  const isInprocess = (
    backgroundProcesses: BackgroundTaskDTO[],
    customerId
  ) => {
    const report = reportStatus.some(
      (status) =>
        status.userId === customerId &&
        status.status === BackgroundTaskStatus.InProgress
    );
    let customerStatus = false;
    if (backgroundProcesses.length > 0) {
      const latestReport = backgroundProcesses.sort((a, b) => b.id - a.id)[0];
      customerStatus =
        latestReport.status === BackgroundTaskStatus.InProgress &&
        latestReport.type === BackgroundTaskType.GenerateUserAnnualReport;
    }
    return report || customerStatus;
  };

  const isCompleted = (backgroundProcesses: BackgroundTaskDTO[]) => {
    let customerStatus = false;
    if (backgroundProcesses.length > 0) {
      const latestReport = backgroundProcesses.sort((a, b) => b.id - a.id)[0];
      customerStatus =
        latestReport.status === BackgroundTaskStatus.Completed &&
        latestReport.type === BackgroundTaskType.GenerateUserAnnualReport;
    }

    return customerStatus;
  };

  return (
    <div className="mt-5 mb-5">
      <div className="flex justify-between roun">
        <span className="p-2 text-xl font-semibold">All Customers</span>
      </div>
      <div className="justify-center text-center mt-4">
        <span className="text-sm font-semibold underline">Reports</span>
        <span className="line"></span>
      </div>
      <div className="flex gap-[40px] justify-center ">
        <div className="flex flex-col gap-3 items-center justify-center p-2 text-xs ">
          {alphabet.map((letter) => (
            <div key={letter} className="text-[13px] font-semibold">
              {letter}
            </div>
          ))}
        </div>
        <div className="max-h-[900px] w-full min-h-[500px]">
          <div className="w-full min-h-[400px] max-h-[900px] overflow-auto">
            <table className=" border-collapse border-white/40 mt-4 table-auto w-full text-sm ">
              <thead className="customer-table">
                <StyledTableHeader>
                  <th className=" font-bold w-2/5 p-2">
                    <button
                      className="flex items-center space-x-1"
                      onClick={() => handleSort()}
                    >
                      <span>Customer Name</span> <img src={FilterIcon} alt="" />
                    </button>
                  </th>
                  <th className="font-bold py-2 w-32">Annual Report</th>
                  {/* <th className="font-bold py-2 w-24">Fact find</th> */}
                  <th className=" font-bold text-center w-28">
                    Goal & Insights
                  </th>
                  <th className="font-bold py-2 px-4 w-max">Financials</th>
                </StyledTableHeader>
              </thead>

              <tbody className="w-full ">
                {displayedData.map((customer) => {
                  return (
                    <tr key={customer.id} className=" border-b">
                      <td>
                        <div className="p-2 flex item-center space-x-2">
                          <img className="w-5 h-5" src={UserIcon} alt="" />
                          <span>
                            {customer.firstName + " " + customer.lastName}
                          </span>
                        </div>
                      </td>
                      <td>
                        {isOutOfDateReport(
                          customer.backgroundTasks,
                          customer
                        ) ? (
                          <Button
                            h="20px"
                            w="76px"
                            px="16px"
                            borderRadius="0.375rem"
                            fontSize="12px"
                            onClick={() => onOrder(customer.id)}
                          >
                            ORDER
                          </Button>
                        ) : isInprocess(
                            customer.backgroundTasks,
                            customer.id
                          ) ? (
                          <button className="report-button">Ordered</button>
                        ) : isCompleted(customer.backgroundTasks) ? (
                          <button className="report-button">
                            <img src={SentIcon} alt="" />
                            Sent
                          </button>
                        ) : null}
                      </td>
                      {/* <td className=" py-2">
          <Button h="20px" px="12px" borderRadius="0.375rem">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          </Button>
        </td> */}
                      <td className=" border-x my-1 flex justify-center px-4">
                        <div className="p-2 flex item-center space-x-2">
                          <button
                            className="flex justify-center items-center space-x-1"
                            onClick={() =>
                              onAssetsTab(
                                `${customer.firstName} ${customer.lastName}`,
                                customer.id,
                                CUSTOMER_TABS.goalsInsights
                              )
                            }
                          >
                            <img className="w-5 h-5" src={BuldIcon} alt="" />
                            <span>View</span>
                          </button>
                        </div>
                      </td>
                      <td className=" py-1 px-4">
                        <div className=" flex gap-[60px]">
                          <button
                            className="flex items-center justify-center space-x-1"
                            onClick={() =>
                              onAssetsTab(
                                `${customer.firstName} ${customer.lastName}`,
                                customer.id,
                                CUSTOMER_TABS.assets
                              )
                            }
                          >
                            <img
                              className="w-5 h-5"
                              src={FinancialIcon}
                              alt=""
                            />
                            <span>View</span>
                          </button>
                          <ExportUserSumerize
                            id={customer.id}
                            customerName={`${customer.firstName} ${customer.lastName}`}
                          ></ExportUserSumerize>
                          <button
                            className="flex items-center justify-center space-x-1"
                            onClick={() =>
                              onAssetsTab(
                                `${customer.firstName} ${customer.lastName}`,
                                customer.id,
                                CUSTOMER_TABS.documents
                              )
                            }
                          >
                            <img
                              className="w-5 h-5"
                              src={DocumentIcon}
                              alt=""
                            />
                            <span>Documents</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div ref={loaderRef}>{isLoading && <Spinner />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
