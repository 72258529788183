import React from "react";

const AccountItem = ({ name, amount }) => {
  return (
    <li className="flex justify-between py-2 border-b border-white/40">
      <span>{name}</span>
      <span>
        {amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </span>
    </li>
  );
};

export default AccountItem;
