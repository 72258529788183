export const PAGE_URL = {
  BASE_URL: "/:brand/:brandid/:office/:officeid",
  LOGIN: "/:brand/:brandid/:office/:officeid/login",
  LOGIN_WITH_PHONE_NUMBER:
    "/:brand/:brandid/:office/:officeid/login-with-phone-number",
  VERIFY: "/:brand/:brandid/:office/:officeid/verify-email-code",
  HOME: "/:brand/:brandid/:office/:officeid",
  CUSTOMERS: "customers",
  ADMIN_CONTENT: "admin-content",
  ADMIN_MESSAGE: "admin-message",
  CUSTOMER_ASSETS: ":id/:customername/:tab",
  RESET_PASSWORD: "/:brand/:brandid/:office/:officeid/password/reset",
  CUSTOMERS_IN_APP:
    "/:brand/:brandid/:office/:officeid/:id/:customername/upload-documents",
};

export const API_URL = {
  BASE: "",
  LOGIN: "/login",
};

export const CUSTOMER_TABS = {
  assets: "assets",
  expenses: "expenses",
  liabilities: "liabilities",
  goalsInsights: "goals-insights",
  documents: "documents",
};

export const VALIDATION = {
  required: {
    regular: "This field is required.",
    email: "User name is required.",
    emailCustomer: "Email is required.",
    password: "Password is required.",
    firstName: "First name is required.",
    lastName: "Last name is required.",
    address: "Address is required.",
    state: "State name is required.",
    postcode: "Post Code is required.",
    phone: "Phone is required.",
  },
  email: {
    value:
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Doesn't match email format.",
  },
  phone: {
    value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    message: "Doesn't match phone format.",
  },
};

export const AllTabs = [
  {
    id: "assets",
    name: "Assets",
  },
  {
    id: "expenses",
    name: "Expenses",
  },
  {
    id: "liabilities",
    name: "Liabilities",
  },
  {
    id: "goals-insights",
    name: "Goals & Insights",
  },
  {
    id: "documents",
    name: "Documents",
  },
];
