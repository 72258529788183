import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Button from "../common/button/Button";
import ExportIcon from "../../images/home/export-icon.svg";
import { useParams } from "react-router-dom";
import * as FileSaver from "file-saver";
import { Period } from "@dineiro/dineiro-sdk-mobile";
import { formatDate } from "../../utils/utils";

const ExportExcel = ({ apiData, period, customer, total }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportDate = new Date();
  const [expenses, setExpenses] = useState();
  const [fileName, setFileName] = useState("");
  const [dayTotals, setDayTotals] = useState("");

  const convertData = () => {
    if (apiData.length > 0) {
      setExpenses(
        apiData.map((expense) => {
          return {
            title: expense.name,
            price: expense.amount.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          };
        })
      );
    }
  };

  const getFileName = () => {
    if (period === Period.ThirtyDays) {
      setFileName(`${formatDate(exportDate)}_30DayTotals_${customer}`);
      setDayTotals("30 days");
    } else if (period === Period.SixtyDays) {
      setFileName(`${formatDate(exportDate)}_60DayTotals_${customer}`);
      setDayTotals("60 days");
    } else if (period === Period.NinetyDays) {
      setFileName(`${formatDate(exportDate)}_90DayTotals_${customer}`);
      setDayTotals("90 days");
    }
  };

  const getSheet = (ws) => {
    if (period === Period.ThirtyDays) {
      return {
        Sheets: { expenses_in_30days: ws },
        SheetNames: ["expenses_in_30days"],
      };
    } else if (period === Period.SixtyDays) {
      return {
        Sheets: { expenses_in_60days: ws },
        SheetNames: ["expenses_in_60days"],
      };
    } else if (period === Period.NinetyDays) {
      return {
        Sheets: { expenses_in_90days: ws },
        SheetNames: ["expenses_in_90days"],
      };
    }
  };

  useEffect(() => {
    convertData();
    getFileName();
  }, [apiData]);

  const exportToCSV = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      ["Customer Name", "", customer],
      ["Day total", "", dayTotals],
      ["Report date", "", formatDate(exportDate)],
      ["", ""],
      ["EXPENSES REPORT :"],
    ]);

    ws["!cols"] = [{ width: 20 }, { width: 20 }, { width: 20 }];
    XLSX.utils.sheet_add_json(ws, expenses, { origin: "B5" });
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "Total",
          "",
          total.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
        ],
      ],
      {
        origin: -1,
      }
    );
    const wb = getSheet(ws);
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      h="40px"
      px="18px"
      borderRadius="0.375rem"
      onClick={() => exportToCSV()}
    >
      <img src={ExportIcon} alt="" />
      <span>Export in Excel</span>
    </Button>
  );
};

export default ExportExcel;
