import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import toast from "react-hot-toast";
import { keyFreeHost } from "../../../environment";

const EditorComponent = ({ setContent, reloadContent }) => {
  const [editorKey, setEditorKey] = useState(Date.now());

  useEffect(() => {
    setEditorKey(Date.now());
  }, [reloadContent]);

  const handleEditorChange = (state) => {
    const contentState = state.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    const options = {
      blockToHTML: (block) => {
        if (block.type === "unstyled") {
          return <p />;
        }
      },
    };
    const htmlString = draftToHtml(contentRaw);
    setContent(htmlString);
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await axios.post(
        `https://api.imgbb.com/1/upload?key=${keyFreeHost}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const imageUrl = response.data.data.url;
        return imageUrl;
      } else {
        toast.error(`Upload Image error: ${response.status}`);
      }
    } catch (error) {
      toast.error(`Upload Image error: ${error}`);
    }
  };
  return (
    <div style={{ backgroundColor: "white", color: "black" }}>
      <Editor
        key={editorKey}
        editorStyle={{ height: "450px", overflow: "auto" }}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={handleEditorChange}
        toolbar={{
          image: {
            previewImage: true,
            uploadCallback: (file) => {
              return new Promise(async (resolve, reject) => {
                const imageUrl = await uploadImage(file);
                console.log(imageUrl);
                resolve({
                  data: {
                    url: imageUrl,
                  },
                });
              });
            },
            alt: { present: true, mandatory: true },
          },
        }}
      />
    </div>
  );
};

export default EditorComponent;
