import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/common/firebase/firebase";
import {
  AccountDTO,
  PropertyDTO,
  VehicleDTO,
} from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useUserProperties = ({ officeId, userId }) => {
  const [properties, setProperties] = useState<PropertyDTO[]>([]);
  const [vehicles, setVehicles] = useState<VehicleDTO[]>([]);
  const [banks, setBanks] = useState<AccountDTO[]>([]);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const userRole = user ? user["roles"][0]["role"]["name"] : null;
  const UserPropertiesComponent = () => {
    useEffect(() => {
      if (user && storedIdToken && userRole !== "customer") {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersPropertiesGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setProperties(response.data);
                }
              });
            await brandOfficesApi
              .brandOfficeCustomersVehiclesGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setVehicles(response.data);
                }
              });
            await brandOfficesApi
              .brandOfficeCustomersAccountsGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setBanks(
                    response.data.filter(
                      (account) => account.container == "Bank"
                    )
                  );
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId]);

    return null;
  };

  UserPropertiesComponent();

  return {
    properties: properties,
    vehicles: vehicles,
    banks: banks,
  };
};

export default useUserProperties;
