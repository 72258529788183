import {
  CONTAINEREnum,
  OverriddenAccountTypeValue,
} from "@dineiro/dineiro-sdk-mobile";
import React, { useEffect, useState } from "react";
import { generateRandomCode, isFileInFolder } from "../../../utils/utils";

const AccountItemWithType = ({
  name,
  amount,
  accountType,
  container = "",
  accountId = 0,
  onChangeTypes,
}) => {
  const [accountTypes, setAccountTypes] = useState([]);
  useEffect(() => {
    var valueArray = Object.entries(OverriddenAccountTypeValue).map(
      ([key, value]) => ({
        id: generateRandomCode(),
        key,
        value,
      })
    );
    if (
      container === CONTAINEREnum.CreditCard ||
      container === CONTAINEREnum.Loan
    ) {
      const list = valueArray.filter(
        (type) =>
          type.value.includes("CREDIT") ||
          type.value.includes("LOAN") ||
          type.value === "MORTGAGE"
      );
      setAccountTypes(list);
    }

    if (container === CONTAINEREnum.Investment) {
      const list = valueArray.filter(
        (type) =>
          type.value === "INDIVIDUAL" ||
          type.value === "TRUST" ||
          type.value === "ANNUITY" ||
          type.value === "SUPER_ANNUATION" ||
          type.value === "SHARES"
      );
      setAccountTypes(list);
    }
  }, [container]);
  return (
    <li className="grid grid-cols-3 py-2 border-b border-white/40">
      <div className="flex justify-start items-center">
        <span>{name}</span>
      </div>

      <div className="flex justify-center ">
        {accountTypes.length > 0 ? (
          <select
            className="text-sm w-1/2 text-left font-semibold bg-transparent border-none outline-none focus:ring-0"
            onChange={(e) => onChangeTypes(e.target.value, accountId)}
          >
            <option value="">{accountType.replace(/_/g, " ")}</option>
            {accountTypes.map((type) => (
              <option value={type.key} key={type.id}>
                {type.value.replace(/_/g, " ")}
              </option>
            ))}
          </select>
        ) : (
          <select className="text-sm w-1/2 text-left font-semibold bg-transparent border-none outline-none focus:ring-0">
            <option value="">{accountType.replace(/_/g, " ")}</option>
          </select>
        )}
      </div>

      <div className="flex justify-end items-center">
        <span>
          {amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      </div>
    </li>
  );
};

export default AccountItemWithType;
