// counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    error: undefined,
  },
  reducers: {
    handleError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleError } = errorSlice.actions;
export default errorSlice.reducer;
