import React, { useEffect, useLayoutEffect, useState } from "react";
import UserIcon from "./../../images/home/userIcon.svg";
import FilterIcon from "./../../images/home/filter-icon.svg";
import Button from "../../components/common/button/Button";
import MyChat from "../../components/message/MessageBox";
import { useParams } from "react-router-dom";
import useCustomers from "../../hooks/useCustomers";
import { getUniqueStartCharacters } from "../../utils/utils";
import Spinner from "../../components/common/spinner/Spinner";
import CustomerTable from "../../components/common/customer-list/CustomersList";
import { StyledCheckBox } from "../../components/common/change-themes/styles-component";
import { Toaster } from "react-hot-toast";

const AdminMessage = () => {
  const [alphabet, setAlphabet] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const { officeid } = useParams();
  const [spinner, setSpinner] = useState(false);
  const { customers } = useCustomers({ officeId: officeid });
  const [selectAll, setSelectAll] = useState(false);
  const [groupChat, setGroupChat] = useState([]);

  const handleCustomerSelect = (data) => {
    if (selectedCustomers.includes(data)) {
      setSelectedCustomers(
        selectedCustomers.filter((customer) => customer.id !== data.id)
      );
    } else {
      setSelectedCustomers([...selectedCustomers, data]);
    }
  };
  const onClearSelection = () => {
    setSelectedCustomers([]);
    if (selectAll) {
      setSelectAll(!selectAll);
    }
  };

  useLayoutEffect(() => {
    const storedProp = localStorage.getItem("brandOfficeName");
    if (storedProp) document.title = `Message - ${storedProp}`;
  }, []);

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      try {
        if (customers.length > 0) {
          const getAlphabet = getUniqueStartCharacters(customers);
          setAlphabet(getAlphabet);
        }
      } catch (err) {
      } finally {
        setSpinner(false);
      }
    }, 2000);
  }, [customers]);

  useEffect(() => {
    if (selectAll) {
      setSelectedCustomers(customers);
    } else setSelectedCustomers([]);
  }, [selectAll]);

  const handleSendMessage = () => {
    const updatedGroupChat = selectedCustomers.map((customer) => ({
      id: customer.id,
      name: customer.firstName + " " + customer.lastName,
      email: customer.email,
      photoUrl: "none",
      welcomeMessage: "hello",
    }));
    setGroupChat(updatedGroupChat);
  };

  return (
    <div className="items-center justify-center p-3 mb-5">
      <div className="p-4 text-center mb-10">
        <h6 className="text-sm">DINEIRO ADMIN</h6>
        <span className=" text-5xl text-center font-bold">
          Message Manager.
        </span>
        <div className="flex items-center justify-center mt-2">
          <div className="h-1 bg-yellow-500 w-24"></div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="flex space-y-2 px-2 w-2/3 mt-5 gap-[40px]">
          <div className="flex flex-col w-full h-[700px] p-2 mt-10">
            <MyChat users={customers} />
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
      {spinner && <Spinner></Spinner>}
    </div>
  );
};

export default AdminMessage;
