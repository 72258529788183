import { useEffect, useState } from "react";

const useModal = (props?) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (typeof props?.show !== "undefined") setShow(props?.show);
  }, [props?.show]);

  function toggle() {
    setShow(!show);
  }

  return {
    show,
    toggle,
  };
};

export default useModal;
