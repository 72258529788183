import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/common/firebase/firebase";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useUserInfo = () => {
  const [userName, setName] = useState("");
  const [userId, setId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [role, setUserRole] = useState("");
  const [brandOfficeId, setBrandOfficeId] = useState(0);
  const [user, loading, error] = useAuthState(auth);
  const UserInfoComponent = () => {
    useEffect(() => {
      if (user && !loading) {
        user
          .getIdToken()
          .then(async (idToken) => {
            const user = jwtDecode(idToken);
            try {
              setName(user["name"]);
              setEmailAddress(user["email"]);
              setBrandOfficeId(user["brandOfficeId"]);
              setId(user["user_id"]);
              const role = user["roles"].find(
                (role) => role["brandOfficeId"] === user["brandOfficeId"]
              );

              setUserRole(role.role.name);
            } catch (err: any) {
              console.log(err);
            }
          })
          .catch();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [user, loading]);

    return null;
  };
  UserInfoComponent();

  return {
    userName: userName,
    brandOfficeId: brandOfficeId,
    emailAddress: emailAddress,
    role: role,
    userId: userId,
    user,
    loading,
  };
};

export default useUserInfo;
