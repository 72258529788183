import React from "react";
import UserIcon from "../../../images/home/userIcon.svg";

const CustomerTable = ({
  customers,
  selectedCustomers,
  handleCustomerSelect,
  alphabet,
  tableHeight,
}) => {
  return (
    <div className="flex gap-[30px]">
      <div className={`w-full max-h-[${tableHeight}] overflow-auto`}>
        <table className="border-collapse mt-1 table-auto w-full text-sm">
          <tbody>
            {customers.map((customer) => {
              const isSelected = selectedCustomers.includes(customer);

              return (
                <tr
                  key={customer.id}
                  className={` ${
                    isSelected ? "pick-user" : ""
                  } cursor-pointer border-b border-white/40`}
                  onClick={() => handleCustomerSelect(customer)}
                >
                  <td className=" py-2">
                    <div className="flex item-center space-x-2 px-2">
                      <img className="w-5 h-5" src={UserIcon} alt="" />
                      <span>
                        {customer.firstName + " " + customer.lastName}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col gap-2 items-center justify-center my-5 text-xs ">
        {alphabet.map((letter) => (
          <div key={letter} className="text-[13px] font-semibold">
            {letter}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerTable;
